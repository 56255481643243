<template>
    <modal-ui
        :id="id"
        :title="$t(state.title)">
        <div class="loader">
            <loader-ui :is-show="state.loader" />
            <div v-if="state.step === 2" class="row">
                <div class="col-12">
                    <div class="alert alert-info">
                        <div class="alert-message">
                            Сфотографируйте лицевую сторону удостоверения или прикрепите файл
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <screen-component
                        :src="state.step2.firstScreen"
                        @on-back="backStep"
                        @on-next="onFirstScreen" />
                </div>
            </div>
            <div v-if="state.step === 3" class="row">
                <div class="col-12">
                    <div class="alert alert-info">
                        <div class="alert-message">
                            Сфотографируйте обратную сторону удостоверения или прикрепите файл
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <screen-component
                        :src="state.step3.secondScreen"
                        @on-back="backStep"
                        @on-next="onSecondScreen" />
                </div>
            </div>
            <div v-if="state.step === 4" class="row">
                <div class="col-12">
                    <div class="alert alert-info">
                        <div class="alert-message">
                            Сфотографируйте лицевую сторону карточки или прикрепите файл
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <screen-component
                        :src="state.step4.thirdScreen"
                        @on-back="backStep"
                        @on-next="onThirdScreen" />
                </div>
            </div>
        </div>
    </modal-ui>
</template>

<script lang="ts">
import { defineComponent, onMounted, onUnmounted, reactive, computed, ref, watch, watchEffect, h } from "vue";
import { initModal, isOpen, useModal } from "@/composables/useModal";
import api from "@/api";
import { ICity } from "@/@types/cities";
import { useDevicesList, useUserMedia } from '@vueuse/core';
import CameraComponent from "@/components/CameraComponent.vue";
import ScreenComponent from "@/components/ScreenComponent.vue";
import { socketOn, socketOff } from "@/composables/useSocket";
import jsonToFormData from '@ajoelp/json-to-formdata';
import { DatePicker } from 'v-calendar';
import moment from "moment";

export default defineComponent({
    name: "HumanStepTwoEModal",
    props: {
        id: {
            type: String,
            default: '',
        },
    },
    components: {
        ScreenComponent, DatePicker,
    },
    setup(props) {
	    const modal = useModal(props.id);

        const state = reactive({
            title: '',
            btn: '',
            step: 2,
            loader: false,
            type: 'add',
            id: 0,
            step2: {
                firstScreen: null,
            },
            step3: {
                secondScreen: null,
            },
            step4: {
                thirdScreen: null,
            },
        });

        onMounted((() => {
            // state.title = 'modals.turnstile.titleAdd';
            state.title = 'Редактирование сканов';
            // state.btn = 'modals.turnstile.btnAdd';
            state.btn = 'Сохранить';

            if (modal.data.hasOwnProperty('human')) {
                if (modal.data.type === 'edit') {
                    state.title = 'Редактирование сканов';
                    state.type = 'edit';
                    state.id = modal.data.human.id;
                    state.step2.firstScreen = modal.data.human.firstScreen;
                    state.step3.secondScreen = modal.data.human.secondScreen;
                    state.step4.thirdScreen = modal.data.human.thirdScreen;
                }
            }
        }));

        onUnmounted(() => {
            // socketOff('placeCard');
        });

        function onFirstScreen(src: any) {
            state.step2.firstScreen = src;
            state.step = 3;
        }

        function onSecondScreen(src: any) {
            state.step3.secondScreen = src;
            state.step = 4;
        }

        function onThirdScreen(src: any) {
            state.step4.thirdScreen = src;
            api.v1.humans.updateStepTwo(state.id, jsonToFormData({
                firstScreen: state.step2.firstScreen,
                secondScreen: state.step3.secondScreen,
                thirdScreen: state.step4.thirdScreen,
            })).then((r: any) => {
                modal.ok({
                    human: r.data.data.human,
                });
            }).finally(() => state.loader = false);
        }

        function formHandler() {

        }

        function backStep() {
            state.step = state.step - 1;
        }

        return {
            state,
            moment,
            formHandler,
            onFirstScreen,
            onSecondScreen,
            onThirdScreen,
            backStep,
        };
    },
});
</script>

<style scoped lang="scss">

</style>
