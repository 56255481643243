<template>
    <div>
        <v-pagination
            v-model="page"
            :pages="getPages"
            :range-size="2"
            :style="getPaginationStyles"
            @update:modelValue="changePage" />
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import VPagination from "@hennge/vue3-pagination";
// import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import { useRoute, useRouter } from "vue-router";
import { syncSetParam, useSearchParams } from "@/composables/useSearchParams";

export default defineComponent({
    name: "PaginationComponent",
    props: {
        pages: {
            type: Number,
            default: 0,
        },
	    records: {
            type: Number,
		    default: 0,
	    },
	    perPage: {
            type: Number,
		    default: 15,
	    },
	    align: {
            type: String,
		    default: 'center',
	    },
    },
    emits: {
	    updateHandler: null,
    },
    setup(props, { emit }) {
        const route = useRoute();
        const { syncSetParam, getParam, hasParam } = useSearchParams();
	    const page = ref(hasParam('page') ? Number(getParam('page')) : 1);

        const getPages = computed(() => {
            if (props.pages !== 0) return props.pages;
            if (props.records !== 0) return Math.ceil(props.records / props.perPage);

            return 0;
        });

        const getAlign = computed(() => {
            if (props.align === 'left') return 'flex-start';
            if (props.align === 'right') return 'flex-end';

            return props.align;
        });

        const getPaginationStyles = computed(() => ({
            'justify-content': getAlign.value,
        }));

        onMounted(() => {
	        if (route.query.hasOwnProperty('page')) {
                if (getPages.value <= Number(route.query.page)) {
	                setPage(getPages.value);
                }
	        }
        });

        function changePage(page: number) {
            syncSetParam('page', page);
            emit('updateHandler', page);
        }

        function setPage(pageNumber: number) {
            page.value = pageNumber;
            syncSetParam('page', pageNumber);
        }

        watch(() => getPages.value, (v) => {
	        setPage(1);
        });

        watch(() => route.query.page, (v) => page.value = v ? Number(v) : 1);

        return {
            page,
	        getPages,
	        getPaginationStyles,
	        changePage,
	        setPage,
        };
    },
    components: {
	    VPagination,
    },
});
</script>

<style lang="scss">
.page-link {
	min-width: 34px;
	height: 32px;
	padding: 0 10px;
	background-color: #fff !important;
	border: 1px solid #dee2e6;
	color: #6c757d;
	display: flex;
	justify-content: center;
	align-content: center;
	align-items: center;
	position: relative;
	line-height: .85rem;
	transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}
.page-link * {
	margin: auto;
	display: block;
}

.page-item-active {
	background-color: #3b7ddd !important;
	border-color: #3b7ddd !important;
	z-index: 3;
}

.Pagination {
	display: flex;
	flex-flow: row;
	flex-wrap: wrap;
	align-items: center;
	margin: 0;
	padding: 0;
	list-style-type: none;
}

.Control {
	position: relative;
	display: block;
	width: 18px;
	height: 18px;
	margin: 0 2px;
	fill: #BBBBBB;
}

.Control-active {
	fill: #333333;
	cursor: pointer;
	transition: fill 0.2s ease-in-out;
}

.Control-active:hover {
	fill: #000000;
	transition: fill 0.2s ease-in-out;
}

.Page:hover {
	border: 1px solid #DEDEDE;
}

.Page-active {
	color: #fff;
	@extend .page-item-active;
}

.DotsHolder {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 22px;
	height: 22px;
	margin: 0 10px;
	box-sizing: border-box;
}

.DotsHolder svg path {
	fill: #333;
}

.Dots {
	width: 8px;
	height: 4px;
	fill: #BBBBBB;
}

.PaginationControl, .Page {
	@extend .page-link
}

.Pagination li:not(:first-child) {
	margin-left: -1px;
}

.Pagination li:first-child{
	border-bottom-left-radius: 0.2rem;
	border-top-left-radius: 0.2rem;
}

.Pagination li:last-child {
	border-bottom-right-radius: 0.3rem;
	border-top-right-radius: 0.3rem;
}
</style>
