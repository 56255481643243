import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_pagination = _resolveComponent("v-pagination")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_pagination, {
      modelValue: _ctx.page,
      "onUpdate:modelValue": [
        _cache[0] || (_cache[0] = ($event: any) => ((_ctx.page) = $event)),
        _ctx.changePage
      ],
      pages: _ctx.getPages,
      "range-size": 2,
      style: _normalizeStyle(_ctx.getPaginationStyles)
    }, null, 8, ["modelValue", "pages", "style", "onUpdate:modelValue"])
  ]))
}