<template>
    <modal-ui
        :id="id"
        :title="$t(state.title)">
        <form class="loader" @submit.prevent="formHandler">
            <loader-ui :is-show="state.loader" />
            <div v-if="state.step === 5" class="row">
                <div class="col-12">
                    <div class="alert alert-info">
                        <div class="alert-message">
                            Загрузите документы в формате JPG, JPEG, PNG или PDF
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-6">
                    <div class="mb-3">
                        <label class="form-label w-100">
                            Договор найма жилого помещения <template v-if="modal.data.human.fileOne"><a :href="API_URL + modal.data.human.fileOne" target="_blank">(Открыть текущий)</a></template>
                        </label>
                        <input
                            ref="file1"
                            :class="{ 'is-invalid': state.step5.visibleErrors && isFile1Error }"
                            type="file"
                            @change="selectFile($event, 'file1')">
                        <div class="invalid-feedback">
                            Выберите документ
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-6">
                    <div class="mb-3">
                        <label class="form-label w-100">
                            Документ, удостоверяющий личность <template v-if="modal.data.human.fileTwo"><a :href="API_URL + modal.data.human.fileTwo" target="_blank">(Открыть текущий)</a></template>
                        </label>
                        <input
                            ref="file2"
                            :class="{ 'is-invalid': state.step5.visibleErrors && isFile2Error }"
                            type="file"
                            @change="selectFile($event, 'file2')">
                        <div class="invalid-feedback">
                            Выберите документ
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-6">
                    <div class="mb-3">
                        <label class="form-label w-100">
                            Документ, подтверждающий обучение в организации образования <template v-if="modal.data.human.fileThree"><a :href="API_URL + modal.data.human.fileThree" target="_blank">(Открыть текущий)</a></template>
                        </label>
                        <input
                            ref="file3"
                            :class="{ 'is-invalid': state.step5.visibleErrors && isFile3Error }"
                            type="file"
                            @change="selectFile($event, 'file3')">
                        <div class="invalid-feedback">
                            Выберите документ
                        </div>
                    </div>
                </div>
                <div class="col-12 text-center pt-2">
                    <button-ui
                        button-class="btn-primary ms-3">
                        {{ $t(state.btn) }}
                    </button-ui>
                </div>
            </div>
        </form>
    </modal-ui>
</template>

<script lang="ts">
import { defineComponent, onMounted, onUnmounted, reactive, computed, ref, watch, watchEffect, h } from "vue";
import { initModal, isOpen, useModal } from "@/composables/useModal";
import api from "@/api";
import { ICity } from "@/@types/cities";
import { useDevicesList, useUserMedia } from '@vueuse/core';
import CameraComponent from "@/components/CameraComponent.vue";
import ScreenComponent from "@/components/ScreenComponent.vue";
import { socketOn, socketOff } from "@/composables/useSocket";
import jsonToFormData from '@ajoelp/json-to-formdata';
import { DatePicker } from 'v-calendar';
import moment from "moment";
import { API_URL } from "@/configs/app";

export default defineComponent({
    name: "HumanAEModal2",
    props: {
        id: {
            type: String,
            default: '',
        },
    },
    components: {
        ScreenComponent, DatePicker,
    },
    setup(props) {
	    const modal = useModal(props.id);
        const file1: any = ref(null);
        const file2: any = ref(null);
        const file3: any = ref(null);

        const state = reactive({
            title: '',
            btn: '',
            step: 5,
            loader: false,
            type: 'add',
            id: 0,
            step5: {
                file1: null,
                file2: null,
                file3: null,
                visibleErrors: false,
            },
        });

        const isFile1Error = computed(() => state.step5.file1 === null);
        const isFile2Error = computed(() => state.step5.file2 === null);
        const isFile3Error = computed(() => state.step5.file3 === null);

        onMounted(() => {
            state.btn = 'Сохранить';

            if (modal.data.hasOwnProperty('human')) {
                if (modal.data.type === 'edit') {
                    state.title = 'Редактирование документов';
                    state.type = 'edit';
                    state.id = modal.data.human.id;
                    state.step5.file1 = modal.data.human.fileOne;
                    state.step5.file2 = modal.data.human.fileTwo;
                    state.step5.file3 = modal.data.human.fileThree;
                }
            }
        });

        function selectFile(e: any, type: string) {
            // var file = e.target.files[0];
            // console.log(file)
            // var imageType = /image.*/;
            // if (!file.type.match(imageType)) return;
            // var form_data = new FormData();
            // form_data.append('file', file);
            // for (var key of form_data.entries()) {
            //     console.log(key[0] + ', ' + key[1]);
            // }
            //
            // console.log(222323, form_data.getAll('file'))



            let file = e.target.files[0];
            if (file) {
                if (/\.(jpe?g|png|pdf)$/i.test(file.name)) {
                    // @ts-ignore
                    let file1Name = state.step5.file1?.name;
                    // @ts-ignore
                    let file2Name = state.step5.file2?.name;
                    // @ts-ignore
                    let file3Name = state.step5.file3?.name;

                    if (type === 'file1' && (file2Name === file.name || file3Name === file.name)) {
                        alert('Файл с таким названием уже загружен');
                        file1.value = '';
                        state.step5.file1 = null;
                    } else if (type === 'file2' && (file1Name === file.name || file3Name === file.name)) {
                        alert('Файл с таким названием уже загружен');
                        file2.value = '';
                        state.step5.file2 = null;
                    } else if (type === 'file3' && (file1Name === file.name || file2Name === file.name)) {
                        alert('Файл с таким названием уже загружен');
                        file3.value = '';
                        state.step5.file3 = null;
                    } else if (type === 'file1'){
                        state.step5.file1 = file;
                    } else if (type === 'file2'){
                        state.step5.file2 = file;
                    } else {
                        state.step5.file3 = file;
                    }
                } else {
                    if (type === 'file1'){
                        file1.value = '';
                        state.step5.file1 = null;
                    } else if (type === 'file2'){
                        file2.value = '';
                        state.step5.file2 = null;
                    } else {
                        file3.value = '';
                        state.step5.file3 = null;
                    }
                    alert('Неверный формат');
                }
            } else if (type === 'file1'){
                file1.value = '';
            } else if (type === 'file2'){
                file2.value = '';
            } else {
                file3.value = '';
            }
        }

        function formHandler() {
            if (isFile1Error.value || isFile2Error.value || isFile3Error.value) {
                state.step5.visibleErrors = true;
            } else {
                api.v1.humans.updateStepThree(state.id, jsonToFormData({
                    file1: state.step5.file1,
                    file2: state.step5.file2,
                    file3: state.step5.file3,
                })).then((r: any) => {
                    modal.ok({
                        human: r.data.data.human,
                    });
                }).finally(() => state.loader = false);
            }
        }

        return {
            modal,
            state,
            moment,
            isFile1Error,
            isFile2Error,
            isFile3Error,
            formHandler,
            selectFile,
            file1,
            file2,
            file3,
            API_URL,
        };
    },
});
</script>

<style scoped lang="scss">

</style>
