<template>
    <div v-if="isShow" class="loader__wrapper">
        <div class="loader__spinner"></div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
    name: "LoaderWidget",
    props: {
	    isShow: Boolean,
    },
    setup(props) {
        // console.log(props.isShow);
    },
});
</script>

<style scoped lang="scss">

</style>