<template>
    <modal-ui
        :id="id"
        size="modal-sm"
        :title="$t(state.title)">
        <form class="loader" @submit.prevent="send">
            <loader-ui :is-show="state.loader" />
            <div class="row mb-4">
                <div class="col-12">
                    <form-group-ui
                        invalid-feedback="modals.turnstile.fields.name.error"
                        label="modals.turnstile.fields.name.label">
                        <input-ui
                            v-model="state.name"
                            :is-invalid="state.visibleErrors && isNameError"
                            placeholder="modals.turnstile.fields.name.placeholder" />
                    </form-group-ui>
                </div>
                <div class="col-12">
                    <form-group-ui
                        invalid-feedback="modals.turnstile.fields.sn.error"
                        label="modals.turnstile.fields.sn.label">
                        <input-ui
                            v-model="state.sn"
                            :is-invalid="state.visibleErrors && isSnError"
                            :mask="{ mask: 'Z*', tokens: { 'Z': { pattern: /[0-9]/ }}}"
                            placeholder="modals.turnstile.fields.sn.placeholder" />
                    </form-group-ui>
                </div>
                <div class="col-12">
                    <form-group-ui
                        invalid-feedback="modals.turnstile.fields.entrance.error"
                        label="modals.turnstile.fields.entrance.label">
                        <select-ui
                            :is-invalid="state.visibleErrors && isEntranceIdError"
                            :options="entrances"
                            placeholder="modals.turnstile.fields.entrance.placeholder"
                            :searchable="true"
                            :values="[state.entranceId]"
                            @on-selected="onSelectedEntrance" />
                    </form-group-ui>
                </div>
                <div class="col-12">
                    <label class="form-check form-check-inline">
                        <input
                            v-model="state.reverseDirection"
                            class="form-check-input"
                            type="checkbox">
                        <span class="form-check-label">{{ $t('modals.turnstile.fields.reverseDirection.label') }}</span>
                    </label>
                </div>
            </div>
            <div class="row">
                <div class="col-12 text-center">
                    <button-ui
                        button-class="btn-primary">
                        {{ $t(state.btn) }}
                    </button-ui>
                </div>
            </div>
        </form>
    </modal-ui>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, computed, ref, watch } from "vue";
import { initModal, useModal } from "@/composables/useModal";
import api from "@/api";
import { ICity } from "@/@types/cities";

export default defineComponent({
    name: "EntranceAEModal",
    props: {
        id: {
            type: String,
            default: '',
        },
    },
    setup(props) {
	    const modal = useModal(props.id);

        const state = reactive({
            title: '',
            type: 'add',
            btn: '',
            id: 0,
            name: '',
            sn: '',
            entranceId: 0,
            reverseDirection: false,
            visibleErrors: false,
            loader: false,
        });
        const entrances = ref([]);

        const isNameError = computed(() => !state.name.length);
        const isEntranceIdError = computed(() => !state.entranceId);
        const isSnError = computed(() => !state.sn.length);

        const errors = computed(() => isNameError.value || isEntranceIdError.value || isSnError.value);

        onMounted((() => {
            state.title = 'modals.turnstile.titleAdd';
            state.btn = 'modals.turnstile.btnAdd';

            api.v1.info.entrances.get().then((r: any) => {
                entrances.value = r.data.data;
            });

            console.log(modal.data)

            if (modal.data.hasOwnProperty('turnstile')) {
                if (modal.data.type === 'edit') {
                    state.title = 'Редактирование турникета';
                    state.btn = 'Сохранить';
                    state.type = 'edit';
                    state.id = modal.data.turnstile.id;
                    state.name = modal.data.turnstile.name;
                    state.sn = modal.data.turnstile.sn;
                    state.entranceId = modal.data.turnstile.entranceId;
                    state.reverseDirection = modal.data.turnstile.reverseDirection;
                }
            }
        }));

        function onSelectedEntrance(entrance: any) {
            state.entranceId = entrance.id;
        }

        function send() {
            if (errors.value) {
                state.visibleErrors = true;
            } else {
                state.visibleErrors = false;
                state.loader = true;
                if (state.type === 'add') {
                    api.v1.turnstiles.create({
                        name: state.name,
                        sn: state.sn,
                        entranceId: state.entranceId,
                        reverseDirection: state.reverseDirection,
                    }).then((r: any) => {
                        modal.ok({
                            turnstile: r.data.data,
                        });
                    }).finally(() => state.loader = false);
                }else{
                    api.v1.turnstiles.update(state.id, {
                        name: state.name,
                        sn: state.sn,
                        entranceId: state.entranceId,
                        reverseDirection: state.reverseDirection,
                    }).then((r: any) => {
                        modal.ok({
                            turnstile: r.data.data,
                        });
                    }).finally(() => state.loader = false);
                }
            }
        }

        return {
            state,
            entrances,
            isNameError,
            isEntranceIdError,
            isSnError,
            onSelectedEntrance,
            send,
        };
    },
});
</script>

<style scoped lang="scss">

</style>
