<template>
    <footer class="footer mt-3">
        <div class="container-fluid">
            <div class="row text-muted">
                <div class="col-auto text-start">
                    <p class="mb-0">
                        <a
                            class="text-muted"
                            href="https://adminkit.io/"
                            target="_blank">
                            <strong>AdminKit</strong>
                        </a> ©
                    </p>
                </div>
                <div v-if="$user.isUser" class="col-auto text-start">
                    Ваша ссылка для PlaceCard: <strong>{{ $user.placeCardLink }}</strong>
                </div>
            </div>
        </div>
    </footer>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
    name: "FooterComponent",
    setup() {

    },
});
</script>

<style scoped lang="scss">

</style>
