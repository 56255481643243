<template>
    <nav class="navbar navbar-expand navbar-light navbar-bg">
        <div class="sidebar-toggle cursor-pointer" @click="changeSidebar">
            <i class="hamburger align-self-center"></i>
        </div>

        <!--        <form class="d-none d-sm-inline-block">-->
        <!--            <div class="input-group input-group-navbar">-->
        <!--                <input-->
        <!--                    aria-label="Search"-->
        <!--                    class="form-control"-->
        <!--                    placeholder="Search…"-->
        <!--                    type="text">-->
        <!--                <button class="btn" type="button">-->
        <!--                    <svg-->
        <!--                        class="feather feather-search align-middle"-->
        <!--                        fill="none"-->
        <!--                        height="24"-->
        <!--                        stroke="currentColor"-->
        <!--                        stroke-linecap="round"-->
        <!--                        stroke-linejoin="round"-->
        <!--                        stroke-width="2"-->
        <!--                        viewBox="0 0 24 24"-->
        <!--                        width="24"-->
        <!--                        xmlns="http://www.w3.org/2000/svg">-->
        <!--                        <circle-->
        <!--                            cx="11"-->
        <!--                            cy="11"-->
        <!--                            r="8" /><line-->
        <!--                                x1="21"-->
        <!--                                x2="16.65"-->
        <!--                                y1="21"-->
        <!--                                y2="16.65" />-->
        <!--                    </svg>-->
        <!--                </button>-->
        <!--            </div>-->
        <!--        </form>-->

        <div class="navbar-collapse collapse">
            <ul class="navbar-nav navbar-align align-items-center">
                <!--                <li class="nav-item me-2">-->
                <!--                    <div v-if="$user.isSuperAdmin" class="badge badge-danger-light">-->
                <!--                        СуперАдмин-->
                <!--                    </div>-->
                <!--                    <div v-if="$user.isAdmin" class="badge badge-danger-light">-->
                <!--                        Администратор-->
                <!--                    </div>-->
                <!--                    <div v-if="$user.isManager" class="badge badge-danger-light">-->
                <!--                        Менеджер-->
                <!--                    </div>-->
                <!--                </li>-->
                <!--                <li class="nav-item dropdown">-->
                <!--                    <dropdown-ui>-->
                <!--                        <template #button>-->
                <!--                            <div class="nav-flag dropdown-toggle">-->
                <!--                                <img-->
                <!--                                    v-if="langCode === 'ru'"-->
                <!--                                    :alt="langCode"-->
                <!--                                    src="../assets/img/flags/ru.png">-->
                <!--                                <img-->
                <!--                                    v-if="langCode === 'en'"-->
                <!--                                    :alt="langCode"-->
                <!--                                    src="../assets/img/flags/en.png">-->
                <!--                                <img-->
                <!--                                    v-if="langCode === 'kz'"-->
                <!--                                    :alt="langCode"-->
                <!--                                    src="../assets/img/flags/kz.png">-->
                <!--                            </div>-->
                <!--                        </template>-->
                <!--                        <template #list>-->
                <!--                            <template v-for="locale in supportLocales" :key="locale.code">-->
                <!--                                <div-->
                <!--                                    class="dropdown-item cursor-pointer"-->
                <!--                                    @click="setLocale(locale.code)">-->
                <!--                                    <img-->
                <!--                                        :alt="locale.name"-->
                <!--                                        class="align-middle me-1"-->
                <!--                                        :src="require('../assets/img/flags/' + locale.code + '.png')"-->
                <!--                                        width="20">-->
                <!--                                    <span class="align-middle">{{ locale.name }}</span>-->
                <!--                                </div>-->
                <!--                            </template>-->
                <!--                        </template>-->
                <!--                    </dropdown-ui>-->
                <!--                </li>-->
                <li class="nav-item">
                    <dropdown-ui>
                        <template #button>
                            <div class="d-flex align-items-center">
                                <div class="nav-icon pe-md-0 dropdown-toggle">
                                    <div class="rounded-circle rounded ava d-flex justify-content-center align-items-center" :style="'background-color: ' + $user.fill">
                                        <vue-feather
                                            size="16"
                                            :style="'color: ' + $user.color"
                                            type="user" />
                                    </div>
                                </div>
                                <div class="ms-3 fw-bolder">
                                    {{ $user.firstName }}
                                    <!--                                    {{ correctPhoneNumber($user.phone) }}-->
                                </div>
                            </div>
                        </template>
                        <template #list>
                            <div class="dropdown-item cursor-pointer" @click="logout">
                                Выход
                            </div>
                        </template>
                    </dropdown-ui>
                </li>
            </ul>
        </div>
    </nav>
</template>

<script lang="ts">
import { defineComponent, toRef, ref } from "vue";
import { i18n, lang, setLocale } from "@/composables/useLocale";
import { SUPPORT_LOCALES } from "@/configs/locales";
import { appStore } from "@/store/appStore";
import { userStore } from "@/store/userStore";
import { useRouter } from "vue-router";
import { isMobile, isNotMobile } from "@/composables/useBreakpoints";
import correctPhoneNumber from "@/utils/correctPhoneNumber";

export default defineComponent({
    name: "HeaderComponent",
    setup() {
        const langCode = ref(lang);
        const supportLocales = SUPPORT_LOCALES;
        const { sidebar, setCompactSidebar, setDefaultSidebar } = appStore();
        const { logout: userLogout, user } = userStore();
        const router = useRouter();

        function changeSidebar() {
            if (!isMobile) {
                if (sidebar.value == 'default') setCompactSidebar();
                else setDefaultSidebar();
            } else {
                let sidebar = <HTMLElement>document.getElementById('sidebar');
                if (sidebar.classList.contains('collapsed')) {
                    sidebar.classList.remove('collapsed');
                } else {
                    sidebar.classList.add('collapsed');
                }
            }
        }

        function logout() {
            userLogout().then((r: any) => {
	            location.href = '/auth';
                // router.push('/auth');
            });
        }

	      return { langCode, supportLocales, setLocale, changeSidebar, logout, correctPhoneNumber };
    },
    components: {
    },
});
</script>

<style scoped lang="scss">
  .ava {
    width: 30px;
    height: 30px;
  }
</style>
