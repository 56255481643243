<template>
    <modal-ui
        :id="id"
        size="modal-md"
        :title="$t(state.title)">
        <form class="loader" @submit.prevent="send">
            <loader-ui :is-show="state.loader" />
            <div class="row mb-4">
                <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <form-group-ui
                        invalid-feedback="modals.entrance.fields.login.error"
                        label="modals.entrance.fields.login.label"
                        required>
                        <input-ui
                            v-model="state.login"
                            :is-invalid="state.visibleErrors && isLoginError"
                            placeholder="modals.entrance.fields.login.placeholder" />
                    </form-group-ui>
                </div>
                <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <form-group-ui
                        :invalid-feedback="passwordInvalidFeedback"
                        label="modals.entrance.fields.password.label"
                        required>
                        <input-ui
                            v-model="state.password"
                            :is-invalid="state.visibleErrors && (isPasswordError ? isPasswordError : passwordChecking)"
                            placeholder="modals.entrance.fields.password.placeholder"
                            type="password" />
                    </form-group-ui>
                </div>
                <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <form-group-ui
                        :invalid-feedback="confirmInvalidFeedback"
                        label="modals.entrance.fields.confirm.label"
                        required>
                        <input-ui
                            v-model="state.confirm"
                            :is-invalid="state.visibleErrors && (isConfirmError ? isConfirmError : passwordChecking)"
                            placeholder="modals.entrance.fields.confirm.placeholder"
                            type="password" />
                    </form-group-ui>
                </div>
                <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <form-group-ui
                        invalid-feedback="modals.entrance.fields.firstName.error"
                        label="modals.entrance.fields.firstName.label"
                        required>
                        <input-ui
                            v-model="state.firstName"
                            :is-invalid="state.visibleErrors && isFirstNameError"
                            placeholder="modals.entrance.fields.firstName.placeholder" />
                    </form-group-ui>
                </div>
                <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <form-group-ui
                        invalid-feedback="modals.entrance.fields.lastName.error"
                        label="modals.entrance.fields.lastName.label"
                        required>
                        <input-ui
                            v-model="state.lastName"
                            :is-invalid="state.visibleErrors && isLastNameError"
                            placeholder="modals.entrance.fields.lastName.placeholder" />
                    </form-group-ui>
                </div>
                <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <form-group-ui
                        label="modals.entrance.fields.middleName.label">
                        <input-ui
                            v-model="state.middleName"
                            placeholder="modals.entrance.fields.middleName.placeholder" />
                    </form-group-ui>
                </div>
                <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <form-group-ui
                        invalid-feedback="modals.entrance.fields.iin.error"
                        label="modals.entrance.fields.iin.label"
                        required>
                        <input-ui
                            v-model="state.iin"
                            :is-invalid="state.visibleErrors && isIinError"
                            :mask="{ mask: 'Z*', tokens: { 'Z': { pattern: /[0-9]/ }}}"
                            maxlength="12"
                            placeholder="modals.entrance.fields.iin.placeholder" />
                    </form-group-ui>
                </div>
                <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <form-group-ui
                        invalid-feedback="modals.entrance.fields.phone.error"
                        label="modals.entrance.fields.phone.label"
                        required>
                        <input-ui
                            v-model="state.phone"
                            :is-invalid="state.visibleErrors && isPhoneError"
                            mask="+7 (###) ###-##-##"
                            placeholder="modals.entrance.fields.phone.placeholder" />
                    </form-group-ui>
                </div>
                <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-6" v-if="!$user.isOVPOSignatory">
                    <form-group-ui
                        invalid-feedback="Выберите учебное заведение"
                        label="Учебное заведение"
                        required>
                        <select-ui
                            :is-invalid="state.visibleErrors && isUnivercityIdError"
                            :options="universities"
                            placeholder="Учебное заведение"
                            :searchable="true"
                            @on-selected="onSelectedUniversity" />
                    </form-group-ui>
                </div>
            </div>
            <div class="row">
                <div class="col-12 text-center">
                    <button-ui
                        button-class="btn-primary">
                        {{ $t(state.btn) }}
                    </button-ui>
                </div>
            </div>
        </form>
    </modal-ui>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, computed, ref, watch } from "vue";
import { initModal, useModal } from "@/composables/useModal";
import api from "@/api";
import { ICity } from "@/@types/cities";
import { userStore } from "@/store/userStore";
import {insert} from "@/composables/useTransmitter";

export default defineComponent({
    name: "OVPOAEModal",
    props: {
        id: {
            type: String,
            default: '',
        },
    },
    setup(props) {
	    const modal = useModal(props.id);
        const { user } = userStore()

        const state = reactive({
            universityId: 0,
            title: '',
            btn: '',
            login: '',
            password: '',
            confirm: '',
            firstName: '',
            lastName: '',
            middleName: '',
            iin: '',
            phone: '',
            visibleErrors: false,
            loader: false,
        });
        const universities = ref([]);

        const isLoginError = computed(() => !state.login.length);
        const isPasswordError = computed(() => !state.password.length);
        const isConfirmError = computed(() => !state.confirm.length);
        const isFirstNameError = computed(() => !state.firstName.length);
        const isLastNameError = computed(() => !state.lastName.length);
        const isUnivercityIdError = computed(() => !state.universityId);
        const isIinError = computed(() => state.iin.length !== 12);
        const isPhoneError = computed(() => state.phone.length !== 18);
        const passwordChecking = computed(() => state.password.length && state.confirm.length && state.password !== state.confirm);
        const passwordInvalidFeedback = computed(() => isPasswordError.value ? 'modals.entrance.fields.password.error' : 'modals.entrance.fields.password.error2');
        const confirmInvalidFeedback = computed(() => isConfirmError.value ? 'modals.entrance.fields.confirm.error' : 'modals.entrance.fields.confirm.error2');

        const errors = computed(() => isLoginError.value ||
            isUnivercityIdError.value ||
            isFirstNameError.value ||
            isLastNameError.value ||
            isIinError.value ||
            isPhoneError.value ||
            isPasswordError.value ||
            isConfirmError.value ||
            passwordChecking.value);

        onMounted((() => {
            state.title = user.isOVPOSignatory ? 'Создать оператора' : 'Создание подписанта';
            state.btn = 'Создать';

            api.v1.info.institutions.get().then((r: any) => {
                universities.value = r.data.data;
            });

            if(user.isOVPOSignatory) {
                api.v1.ovpo.get(5).then(({ data }: any) => {
                    let u = data.data.find((i: any) => i.userId === user.id)
                    state.universityId = u.institutionId
                });
            }
        }));

        function onSelectedUniversity(i: any) {
            state.universityId = i.id
        }

        function send() {
            if (errors.value) {
                state.visibleErrors = true;
            } else {
                state.visibleErrors = false;
                state.loader = true;
                api.v1.ovpo.create({
                    login: state.login,
                    password: state.password,
                    firstName: state.firstName,
                    lastName: state.lastName,
                    middleName: state.middleName || null,
                    universityId: state.universityId,
                    roleId: user.isOVPOSignatory ? 6 : 5,
                    iin: state.iin,
                    phone: state.phone.replace(/[^0-9]/ig, ''),
                }).then((r: any) => {
                    modal.ok({
                        ovpo: r.data.data,
                    });
                }).finally(() => state.loader = false);
            }
        }

	    function ok() {
		    modal.ok({
			    asd: 123,
		    });
	    }

        function close() {
            modal.close();
        }

	    function cancel() {
		    modal.cancel({
			    zxc: 123,
		    });
	    }

        return {
            ok,
            cancel,
            close,
            state,
            universities,
            isLoginError,
            isFirstNameError,
            isLastNameError,
            isIinError,
            isUnivercityIdError,
            isPhoneError,
            isPasswordError,
            isConfirmError,
            passwordChecking,
            passwordInvalidFeedback,
            confirmInvalidFeedback,
            onSelectedUniversity,
            send,
        };
    },
});
</script>

<style scoped lang="scss">

</style>
