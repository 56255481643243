<template>
    <modal-ui
        :id="id"
        size="modal-sm"
        :title="$t('modals.checkCard.title')">
        <div v-if="errorCode === 'human_not_exist'">
            <div class="alert alert-danger m-0">
                <div class="alert-message text-center">
                    {{ $t('modals.checkCard.humanNotExist') }}
                </div>
            </div>
        </div>
        <div v-if="errorCode === 'human_exist'">
            <div class="alert alert-danger m-0">
                <div class="alert-message text-center">
                    {{ $t('modals.checkCard.humanExist') }}
                </div>
            </div>
        </div>
        <div v-if="human !== null">
            <div class="h4 text-center mb-2">
                {{ human.firstName }} {{ human.lastName }} {{ human.middleName }}
            </div>
            <!--            <div v-if="human.active" class="text-center">-->
            <!--                Статус: <strong class="text-success">Активен</strong>-->
            <!--            </div>-->
            <!--            <div v-if="!human.active" class="text-center">-->
            <!--                Статус: <strong class="text-danger">Не активен</strong>-->
            <!--            </div>-->
            <div class="text-center mb-3">
                ИИН: <strong>{{ human.iin }}</strong>
            </div>
        </div>
    </modal-ui>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { initModal, useModal } from "@/composables/useModal";

export default defineComponent({
    name: "CheckCardModal",
    props: {
        id: {
            type: String,
            default: '',
        },
    },
    setup: function (props) {
        const modal = useModal(props.id);
        const errorCode = ref(null);
        const human = ref(null);

        onMounted(() => {
            if (modal.data.hasOwnProperty('errorCode')) {
                errorCode.value = modal.data.errorCode;
            }
            if (modal.data.hasOwnProperty('human')) {
                human.value = modal.data.human;
            }
        });

        function ok() {
            modal.ok({
                asd: 123,
            });
        }

        function cancel() {
            modal.cancel({
                zxc: 123,
            });
        }

        return {
            ok, cancel, errorCode, human,
        };
    },
});
</script>

<style scoped lang="scss">

</style>
