<template>
    <modal-ui
        :id="id"
        :title="title">
        <div class="list">
            <div v-for="item in list" class="d-flex py-3 item">
                <div class="col-auto">{{ moment(item.date).format('DD MM YYYY HH:mm:ss') }}</div>
                <div class="col-auto w-full ms-4" v-html="item.data"></div>
            </div>
        </div>
    </modal-ui>
</template>

<script lang="ts">
import {defineComponent, onMounted, onUnmounted, Ref, ref} from "vue";
import { initModal, useModal } from "@/composables/useModal";
import {socketOff, socketOn} from "@/composables/useSocket";
import moment from 'moment'

export default defineComponent({
    name: "TestModal",
    props: {
        id: {
            type: String,
            default: '',
        },
    },
    setup(props) {
	    const modal = useModal(props.id);
        const list = ref([]) as Ref<Array<{ sn: number, date: Date, data: string }>>
        const title = ref('')

        onMounted(() => {
            title.value = 'Логи турникета ' + modal.data.turnstile.name + ' ' + modal.data.turnstile.sn
        })

        socketOn('monitoring', (r: { sn: number, date: Date, data: string }) => {
            if(modal.data.turnstile.sn === r.sn) {
                //@ts-ignore
                list.value.unshift(r)
            }

            if(list.value.length > 15) {
                list.value.splice(list.value.length - 1, 1)
            }
        });


        onUnmounted(() => {
            socketOff('monitoring')
        })

	    function ok() {
		    modal.ok({
			    asd: 123,
		    });
	    }

        function close() {
            modal.close();
        }

	    function cancel() {
		    modal.cancel({
			    zxc: 123,
		    });
	    }

        return {
            ok, cancel, close, title, list, moment
        };
    },
});
</script>

<style scoped lang="scss">

.list .item:nth-child(1) {
    padding-top: 0 !important;
}
.list .item:nth-last-child(1) {
    padding-bottom: 0 !important;
    border: none !important;
}

.item {
    border-bottom: 1px solid #cccccc
}
</style>
