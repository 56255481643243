import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row mb-4" }
const _hoisted_2 = { class: "col-12" }
const _hoisted_3 = { class: "col-12" }
const _hoisted_4 = { class: "col-12" }
const _hoisted_5 = { class: "col-12" }
const _hoisted_6 = { class: "col-12" }
const _hoisted_7 = { class: "col-12" }
const _hoisted_8 = { class: "col-12" }
const _hoisted_9 = { class: "col-12" }
const _hoisted_10 = { class: "col-12" }
const _hoisted_11 = { class: "row" }
const _hoisted_12 = { class: "col-12 text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loader_ui = _resolveComponent("loader-ui")!
  const _component_input_ui = _resolveComponent("input-ui")!
  const _component_form_group_ui = _resolveComponent("form-group-ui")!
  const _component_select_ui = _resolveComponent("select-ui")!
  const _component_button_ui = _resolveComponent("button-ui")!
  const _component_modal_ui = _resolveComponent("modal-ui")!

  return (_openBlock(), _createBlock(_component_modal_ui, {
    id: _ctx.id,
    size: "modal-sm",
    title: _ctx.$t(_ctx.state.title)
  }, {
    default: _withCtx(() => [
      _createElementVNode("form", {
        class: "loader",
        onSubmit: _cache[7] || (_cache[7] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.send && _ctx.send(...args)), ["prevent"]))
      }, [
        _createVNode(_component_loader_ui, {
          "is-show": _ctx.state.loader
        }, null, 8, ["is-show"]),
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_form_group_ui, {
              "invalid-feedback": "Укажите наименование",
              label: "Наименование",
              required: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_input_ui, {
                  modelValue: _ctx.state.name,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.name) = $event)),
                  "is-invalid": _ctx.state.visibleErrors && _ctx.isNameError,
                  placeholder: "Наименование"
                }, null, 8, ["modelValue", "is-invalid"])
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_form_group_ui, {
              "invalid-feedback": "Укажите адрес",
              label: "Адрес",
              required: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_input_ui, {
                  modelValue: _ctx.state.address,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.state.address) = $event)),
                  "is-invalid": _ctx.state.visibleErrors && _ctx.isAddressError,
                  placeholder: "Адрес"
                }, null, 8, ["modelValue", "is-invalid"])
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_form_group_ui, {
              "invalid-feedback": "Выберите город",
              label: "Город",
              required: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_select_ui, {
                  "is-invalid": _ctx.state.visibleErrors && _ctx.isCityIdError,
                  options: _ctx.cities,
                  placeholder: "Город",
                  searchable: true,
                  values: [_ctx.state.cityId],
                  onOnSelected: _ctx.onSelectedCity
                }, null, 8, ["is-invalid", "options", "values", "onOnSelected"])
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_form_group_ui, {
              "invalid-feedback": "Выберите тип",
              label: "Тип",
              required: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_select_ui, {
                  "is-invalid": _ctx.state.visibleErrors && _ctx.isTypeIdError,
                  options: _ctx.types,
                  placeholder: "Тип",
                  values: [_ctx.state.typeId],
                  onOnSelected: _ctx.onSelectedType
                }, null, 8, ["is-invalid", "options", "values", "onOnSelected"])
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_form_group_ui, {
              "invalid-feedback": "Укажите ИИН руководителя",
              label: "ИИН руководителя",
              required: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_input_ui, {
                  modelValue: _ctx.state.institutionIin,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.state.institutionIin) = $event)),
                  "is-invalid": _ctx.state.visibleErrors && _ctx.isInstitutionIinError,
                  mask: { mask: 'Z*', tokens: { 'Z': { pattern: /[0-9]/ }}},
                  maxlength: "12",
                  minlength: "12",
                  placeholder: "ИИН руководителя"
                }, null, 8, ["modelValue", "is-invalid"])
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_form_group_ui, {
              "invalid-feedback": "Укажите БИН ЮЛ",
              label: "БИН ЮЛ",
              required: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_input_ui, {
                  modelValue: _ctx.state.institutionBin,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.state.institutionBin) = $event)),
                  "is-invalid": _ctx.state.visibleErrors && _ctx.isInstitutionBinError,
                  mask: { mask: 'Z*', tokens: { 'Z': { pattern: /[0-9]/ }}},
                  maxlength: "12",
                  minlength: "12",
                  placeholder: "БИН ЮЛ"
                }, null, 8, ["modelValue", "is-invalid"])
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_form_group_ui, {
              "invalid-feedback": "Укажите имя руководителя",
              label: "Имя руководителя",
              required: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_input_ui, {
                  modelValue: _ctx.state.institutionFirstName,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.state.institutionFirstName) = $event)),
                  "is-invalid": _ctx.state.visibleErrors && _ctx.isInstitutionFirstNameError,
                  placeholder: "Имя руководителя"
                }, null, 8, ["modelValue", "is-invalid"])
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_form_group_ui, {
              "invalid-feedback": "Укажите фамилию руководителя",
              label: "Фамилия руководителя",
              required: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_input_ui, {
                  modelValue: _ctx.state.institutionLastName,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.state.institutionLastName) = $event)),
                  "is-invalid": _ctx.state.visibleErrors && _ctx.isInstitutionLastNameError,
                  placeholder: "Фамилия руководителя"
                }, null, 8, ["modelValue", "is-invalid"])
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createVNode(_component_form_group_ui, {
              "invalid-feedback": "Укажите отчество руководителя",
              label: "Отчество руководителя"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_input_ui, {
                  modelValue: _ctx.state.institutionMiddleName,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.state.institutionMiddleName) = $event)),
                  "is-invalid": _ctx.state.visibleErrors && _ctx.isInstitutionMiddleNameError,
                  placeholder: "Отчество руководителя"
                }, null, 8, ["modelValue", "is-invalid"])
              ]),
              _: 1
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, [
            _createVNode(_component_button_ui, { "button-class": "btn-primary" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t(_ctx.state.btn)), 1)
              ]),
              _: 1
            })
          ])
        ])
      ], 32)
    ]),
    _: 1
  }, 8, ["id", "title"]))
}