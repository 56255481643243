<template>
    <popper
        arrow
        :hover="true"
        placement="top">
        <template #content>
            {{ content }}
        </template>
        <slot></slot>
    </popper>
</template>

<script lang="ts">
import { defineComponent } from "vue";
// @ts-ignore
import Popper from "vue3-popper";

export default defineComponent({
    name: "PopoverUi",
    props: {
        content: {
            type: String,
            default: '',
        },
    },
    components: {
        Popper,
    },
    setup() {

    },
});
</script>

<style scoped lang="scss">

</style>