<template>
    <modal-ui
        :id="id"
        size="modal-lg"
        title="Информация о студенте">
        <div class="row">
            <div class="col-12">
                <h4>Общие данные</h4>
            </div>
            <div class="col-12">
                <table class="table table-bordered">
                    <tr>
                        <td class="w-50 py-2">
                            <span class="p-0"><b>ФИО:</b> {{ getFullName() }}</span>
                        </td>
                        <td class="w-50 py-2">
                            <span class="p-0"><b>ИИН:</b> {{ human.iin }}</span>
                        </td>
                    </tr>
                    <tr>
                        <td class="py-2">
                            <span class="p-0"><b>Тип:</b> {{ human.roleId === 1 ? 'Студент' : 'Сотрудник' }} - {{ human.entranceName }}</span>
                        </td>
                        <td class="py-2">
                            <span class="p-0"><b>Номер телефона:</b> {{ human.phone ? human.phone.replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, '+$1 ($2) $3-$4-$5') : '-' }}</span>
                        </td>
                    </tr>
                    <tr>
                        <td class="py-2">
                            <span class="p-0"><b>Код карты:</b> {{ human.rfid }}</span>
                        </td>
                        <td class="py-2">
                            <span class="p-0"><b>Учебное заведение:</b> {{ human.prop1 ? institutions.find((i: any) => i.id === human.prop1).name : '-' }}</span>
                        </td>
                    </tr>
                    <tr>
                        <td class="py-2">
                            <span class="p-0"><b>Форма обучение:</b> {{ educations.find((i: any) => i.id === human.prop1)?.name }}</span>
                        </td>
                        <td class="py-2">
                            <span class="p-0"><b>Дата поступления:</b> {{ human.enteredAt ? moment(human.enteredAt).locale('ru').format('DD MMMM YYYY') : '-' }}</span>
                        </td>
                    </tr>
                    <tr>
                        <td class="py-2">
                            <span class="p-0"><b>Номер комнаты:</b> {{ human.roomNumber ? human.roomNumber : '-' }}</span>
                        </td>
                        <td class="py-2">
                            <span class="p-0"><b>Курс обучения:</b> {{ human.courseNumber ? human.courseNumber : '-' }}</span>
                        </td>
                    </tr>
                    <tr>
                        <td class="py-2">
                            <span class="p-0"><b>Номер договора:</b> {{ human.contractNumber ? human.contractNumber : '-' }}</span>
                        </td>
                        <td class="py-2">
                            <span class="p-0"><b>Дата заключения договора:</b> {{ human.contractDate ? moment(human.contractDate).locale('ru').format('DD MMMM YYYY') : '-' }}</span>
                        </td>
                    </tr>
                </table>
            </div>
            <div class="col-12 mt-3">
                <h4>Удостоверение и карточка</h4>
            </div>
            <div class="col-12">
                <div class="row">
                    <div class="col-12 col-lg-4">
                        <a
                            v-if="human.firstScreen"
                            :href="API_URL + human.firstScreen"
                            target="_blank">
                            Лицевая сторона удостоверения
                            <img class="w-100" :src="API_URL + human.firstScreen">
                        </a>
                        <div v-else>
                            Лицевая сторона удостоверения
                        </div>
                    </div>
                    <div class="col-12 col-lg-4">
                        <a
                            v-if="human.secondScreen"
                            :href="API_URL + human.secondScreen"
                            target="_blank">
                            Обратная сторона удостоверения
                            <img class="w-100" :src="API_URL + human.secondScreen">
                        </a>
                        <div v-else>
                            Обратная сторона удостоверения
                        </div>
                    </div>
                    <div class="col-12 col-lg-4">
                        <a
                            v-if="human.thirdScreen"
                            :href="API_URL + human.thirdScreen"
                            target="_blank">
                            Лицевая сторона карточки
                            <img class="w-100" :src="API_URL + human.thirdScreen">
                        </a>
                        <div v-else>
                            Лицевая сторона карточки
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 mt-3">
                <h4>Документы</h4>
            </div>
            <div class="col-12">
                <table class="table table-bordered">
                    <tr>
                        <td class="py-2">
                            <span class="p-0">
                                <a
                                    v-if="human.fileOne"
                                    :href="API_URL + human.fileOne"
                                    target="_blank">
                                    Договор найма жилого помещения
                                </a>
                                <div v-else>Договор найма жилого помещения</div>
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td class="py-2">
                            <span class="p-0">
                                <a
                                    v-if="human.fileTwo"
                                    :href="API_URL + human.fileTwo"
                                    target="_blank">
                                    Документ, удостоверяющий личность
                                </a>
                                <div v-else>Документ, удостоверяющий личность</div>
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td class="py-2">
                            <span class="p-0">
                                <a
                                    v-if="human.fileThree"
                                    :href="API_URL + human.fileThree"
                                    target="_blank">
                                    Документ, подтверждающий обучение в организации образования
                                </a>
                                <div v-else>Документ, подтверждающий обучение в организации образования</div>
                            </span>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </modal-ui>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { initModal, useModal } from "@/composables/useModal";
import { IHuman } from "@/@types/humans";
import moment from 'moment';
import api from "@/api";
import { API_URL } from "@/configs/app";

export default defineComponent({
    name: "HumanInfoModal",
    props: {
        id: {
            type: String,
            default: '',
        },
    },
    setup: function (props) {
        const modal = useModal(props.id);
        const errorCode = ref(null);
        const human = ref({});
        const institutions = ref([]);
        const educations = ref([]);

        onMounted(() => {
            human.value = modal.data.human;

            api.v1.info.institutions.get().then((r: any) => {
                institutions.value = r.data.data;
            });

            api.v1.info.educations.get().then((r: any) => {
                educations.value = r.data.data;
            });
        });

        function getFullName() {
            let arr = [];
            // @ts-ignore
            if (human.value.hasOwnProperty('firstName')) arr.push(human.value.firstName);
            // @ts-ignore
            if (human.value.hasOwnProperty('lastName')) arr.push(human.value.lastName);
            // @ts-ignore
            if (human.value.hasOwnProperty('middleName')) arr.push(human.value.middleName);

            return arr.join(' ');
        }

        function ok() {
            modal.ok({
                asd: 123,
            });
        }

        function cancel() {
            modal.cancel({
                zxc: 123,
            });
        }

        return {
            ok, cancel, errorCode, human, getFullName, moment, institutions, educations, API_URL,
        };
    },
});
</script>

<style scoped lang="scss">

</style>
