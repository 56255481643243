<template>
    <input
        v-model="getModel"
        v-maska="getMask"
        :autocomplete="autocomplete"
        :class="getInputClass"
        :disabled="getDisabled"
        :maxlength="maxlength"
        :name="name"
        :placeholder="getPlaceholder"
        :readonly="readonly"
        :type="type">
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import { maska } from 'maska';
import { i18n } from '@/composables/useLocale';
import t from '@/utils/t';

export default defineComponent({
    name: "InputUi",
    props: {
        modelValue: {
            type: [ String, Number ],
            default: '',
        },
        inputClass: {
            type: String,
            default: 'form-control',
        },
        isInvalid: {
            type: [ Boolean, Number ],
            default: false,
        },
        isValid: {
            type: [ Boolean, Number ],
            default: false,
        },
        maxlength: {
            type: [ Number, String ],
            default: null,
        },
        autocomplete: {
            type: Boolean,
            default: true,
        },
        name: {
            type: String,
            default: null,
        },
        type: {
            type: String,
            default: 'text',
        },
        mask: {
            type: [ Array, String, Object ],
            default: null,
        },
        placeholder: {
            type: String,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: null,
        },
        readonly: {
            type: String,
            default: null,
        },
    },
    emits: ['update:modelValue'],
    directives: { maska },
    setup(props, { attrs, emit, slots }) {
        const getModel = computed({
            get() {
                return props.modelValue;
            },
            set(value) {
                return emit('update:modelValue', value);
            },
        });

        const getInputClass = computed(() => {
            let defaultClass = props.inputClass;
            let isInvalid = props.isInvalid ? 'is-invalid' : null;
            let isValid = props.isValid ? 'is-valid' : null;
            return [ defaultClass, isInvalid, isValid ].filter(i => i).join(' ');
        });

        const getMask = computed(() => {
            return props.mask;
        });

        const getPlaceholder = computed(() => props.placeholder ? t(props.placeholder) : '');

        const getDisabled = computed(() => props.disabled ? 'disabled' : null);

        return {
            getModel, getInputClass, getMask, getPlaceholder, getDisabled,
        };
    },
});
</script>

<style scoped lang="scss">

</style>
