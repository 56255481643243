import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  id: "sidebar",
  class: "sidebar"
}
const _hoisted_2 = { class: "sidebar-brand-text align-middle" }
const _hoisted_3 = { class: "sidebar-nav" }
const _hoisted_4 = {
  key: 0,
  class: "sidebar-header"
}
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "align-middle" }
const _hoisted_7 = { class: "align-middle" }
const _hoisted_8 = {
  key: 2,
  class: "sidebar-dropdown list-unstyled"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_logo_white = _resolveComponent("logo-white")!
  const _component_logo_white_min = _resolveComponent("logo-white-min")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_simple_bar = _resolveComponent("simple-bar")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createVNode(_component_simple_bar, { class: "sidebar-content" }, {
      default: _withCtx(() => [
        _createVNode(_component_router_link, {
          class: "sidebar-brand p-3 m-0 d-flex align-items-center ms-2",
          to: "/"
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", _hoisted_2, [
              _createVNode(_component_logo_white, { height: "32px" })
            ]),
            _createVNode(_component_logo_white_min, {
              class: "sidebar-brand-icon align-middle",
              height: "32px"
            })
          ]),
          _: 1
        }),
        _createElementVNode("ul", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menu, (group, groupInd) => {
            return (_openBlock(), _createElementBlock(_Fragment, {
              key: 'group' + groupInd
            }, [
              (group.groupName !== null && group.isShow)
                ? (_openBlock(), _createElementBlock("li", _hoisted_4, _toDisplayString(_ctx.$t(group.groupName)), 1))
                : _createCommentVNode("", true),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(group.children, (item, itemInd) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: 'item' + itemInd,
                  class: _normalizeClass(["sidebar-item", { active: _ctx.isActive(item) }])
                }, [
                  (item.hasOwnProperty('children') && item.isShow)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: _normalizeClass(["sidebar-link no-select", { collapsed: !item.open || _ctx.isActive(item) }]),
                        "data-bs-toggle": "collapse",
                        onClick: ($event: any) => (item.open = !item.open)
                      }, [
                        (_openBlock(), _createBlock(_resolveDynamicComponent(item.icon))),
                        _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t(item.title)), 1)
                      ], 10, _hoisted_5))
                    : _createCommentVNode("", true),
                  (!item.hasOwnProperty('children') && item.isShow)
                    ? (_openBlock(), _createBlock(_component_router_link, {
                        key: 1,
                        class: _normalizeClass(["sidebar-link no-select", { active: _ctx.isActive(item) }]),
                        to: { name: item.routeName }
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(), _createBlock(_resolveDynamicComponent(item.icon))),
                          _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t(item.title)), 1)
                        ]),
                        _: 2
                      }, 1032, ["class", "to"]))
                    : _createCommentVNode("", true),
                  (item.hasOwnProperty('children') && (item.open || _ctx.isActive(item)))
                    ? (_openBlock(), _createElementBlock("ul", _hoisted_8, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.children, (sub, subInd) => {
                          return (_openBlock(), _createElementBlock(_Fragment, {
                            key: 'sub' + subInd
                          }, [
                            (sub.isShow)
                              ? (_openBlock(), _createElementBlock("li", {
                                  key: 0,
                                  class: _normalizeClass(["sidebar-item", { active: _ctx.isActive(item) }])
                                }, [
                                  _createVNode(_component_router_link, {
                                    class: "sidebar-link no-select",
                                    to: { name: sub.routeName }
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.$t(sub.title)), 1)
                                    ]),
                                    _: 2
                                  }, 1032, ["to"])
                                ], 2))
                              : _createCommentVNode("", true)
                          ], 64))
                        }), 128))
                      ]))
                    : _createCommentVNode("", true)
                ], 2))
              }), 128))
            ], 64))
          }), 128))
        ])
      ]),
      _: 1
    })
  ]))
}