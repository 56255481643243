<template>
    <modal-ui
        :id="id"
        size="modal-md"
        :title="$t('Сброс пароля')">
        <form class="loader" @submit.prevent="requestResetLink">
            <loader-ui :is-show="state.loader" />
            <div class="row mb-4">
                <div class="col-12 mb-2">
                    <div class="form-group">
                        <label>Логин</label>
                        <div class="input-group">
                            <input-ui
                                v-model="state.login"
                                readonly />
                            <button class="btn btn-outline-secondary" type="button" @click="copyToClipboard(state.login)">Копировать</button>
                        </div>
                    </div>
                </div>
                <div class="col-12 mb-2">
                    <div class="form-group">
                        <label>Телефон</label>
                        <div class="input-group">
                            <input-ui
                                v-model="state.phone"
                                readonly />
                            <button class="btn btn-outline-secondary" type="button" @click="copyToClipboard(state.phone)">Копировать</button>
                        </div>
                    </div>
                </div>
                <div v-if="state.resetLink" class="col-12 mb-2">
                    <div class="form-group">
                        <label>Ссылка для сброса пароля</label>
                        <div class="input-group">
                            <input-ui
                                v-model="state.resetLink"
                                readonly />
                            <button class="btn btn-outline-secondary" type="button" @click="copyToClipboard(state.resetLink)">Копировать</button>
                        </div>
                    </div>
                </div>
                <div class="col-12 text-center">
                    <p>Ссылка активна в течение 3 дней.</p>
                </div>
            </div>
            <div class="row">
                <div class="col-12 text-center">
                    <button-ui
                        button-class="btn-primary"
                        @click="requestResetLink">
                        {{ $t('Получить ссылку для сброса пароля') }}
                    </button-ui>
                </div>
            </div>
        </form>
    </modal-ui>
</template>

<script lang="ts">
import { defineComponent, reactive, ref } from "vue";
import { initModal, useModal } from "@/composables/useModal";
import api from "@/api";
import useNotifications from "@/composables/useNotifications";

export default defineComponent({
    name: "ResetPasswordModal",
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    setup(props) {
        const modal = useModal(props.id);
        const errorCode = ref(null);
        const state = reactive({
            resetLink: '',
            loader: false,
            login: modal.data.user.login,
            phone: modal.data.user.phone,
        });

        function copyToClipboard(text: string) {
            navigator.clipboard.writeText(text).then(() => {
                useNotifications.success('Успех', 'Скопировано в буфер обмена');
            });
        }

        async function requestResetLink() {
            if (state.loader) return;
            state.loader = true;

            try {
                const response = await api.v1.users.resetPassword(modal.data.user.id);
                state.resetLink = response.data.data.resetLink;
            } catch (error) {
                useNotifications.error('Ошибка', 'Не удалось получить ссылку для сброса пароля');
            } finally {
                state.loader = false;
            }
        }

        function ok() {
            modal.ok({
                asd: 123,
            });
        }

        function cancel() {
            modal.cancel({
                zxc: 123,
            });
        }

        return {
            ok,
            cancel,
            errorCode,
            state,
            copyToClipboard,
            requestResetLink,
        };
    },
});
</script>

<style scoped lang="scss">

</style>
