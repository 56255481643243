import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row mb-4" }
const _hoisted_2 = { class: "col-md-6 col-lg-6 col-xl-6 col-xxl-6" }
const _hoisted_3 = { class: "col-md-6 col-lg-6 col-xl-6 col-xxl-6" }
const _hoisted_4 = { class: "col-md-6 col-lg-6 col-xl-6 col-xxl-6" }
const _hoisted_5 = { class: "col-md-6 col-lg-6 col-xl-6 col-xxl-6" }
const _hoisted_6 = { class: "col-md-6 col-lg-6 col-xl-6 col-xxl-6" }
const _hoisted_7 = { class: "col-md-6 col-lg-6 col-xl-6 col-xxl-6" }
const _hoisted_8 = { class: "col-md-6 col-lg-6 col-xl-6 col-xxl-6" }
const _hoisted_9 = { class: "col-md-6 col-lg-6 col-xl-6 col-xxl-6" }
const _hoisted_10 = {
  key: 0,
  class: "col-md-6 col-lg-6 col-xl-6 col-xxl-6"
}
const _hoisted_11 = { class: "row" }
const _hoisted_12 = { class: "col-12 text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loader_ui = _resolveComponent("loader-ui")!
  const _component_input_ui = _resolveComponent("input-ui")!
  const _component_form_group_ui = _resolveComponent("form-group-ui")!
  const _component_select_ui = _resolveComponent("select-ui")!
  const _component_button_ui = _resolveComponent("button-ui")!
  const _component_modal_ui = _resolveComponent("modal-ui")!

  return (_openBlock(), _createBlock(_component_modal_ui, {
    id: _ctx.id,
    size: "modal-md",
    title: _ctx.$t(_ctx.state.title)
  }, {
    default: _withCtx(() => [
      _createElementVNode("form", {
        class: "loader",
        onSubmit: _cache[8] || (_cache[8] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.send && _ctx.send(...args)), ["prevent"]))
      }, [
        _createVNode(_component_loader_ui, {
          "is-show": _ctx.state.loader
        }, null, 8, ["is-show"]),
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_form_group_ui, {
              "invalid-feedback": "modals.entrance.fields.login.error",
              label: "modals.entrance.fields.login.label",
              required: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_input_ui, {
                  modelValue: _ctx.state.login,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.login) = $event)),
                  "is-invalid": _ctx.state.visibleErrors && _ctx.isLoginError,
                  placeholder: "modals.entrance.fields.login.placeholder"
                }, null, 8, ["modelValue", "is-invalid"])
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_form_group_ui, {
              "invalid-feedback": _ctx.passwordInvalidFeedback,
              label: "modals.entrance.fields.password.label",
              required: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_input_ui, {
                  modelValue: _ctx.state.password,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.state.password) = $event)),
                  "is-invalid": _ctx.state.visibleErrors && (_ctx.isPasswordError ? _ctx.isPasswordError : _ctx.passwordChecking),
                  placeholder: "modals.entrance.fields.password.placeholder",
                  type: "password"
                }, null, 8, ["modelValue", "is-invalid"])
              ]),
              _: 1
            }, 8, ["invalid-feedback"])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_form_group_ui, {
              "invalid-feedback": _ctx.confirmInvalidFeedback,
              label: "modals.entrance.fields.confirm.label",
              required: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_input_ui, {
                  modelValue: _ctx.state.confirm,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.state.confirm) = $event)),
                  "is-invalid": _ctx.state.visibleErrors && (_ctx.isConfirmError ? _ctx.isConfirmError : _ctx.passwordChecking),
                  placeholder: "modals.entrance.fields.confirm.placeholder",
                  type: "password"
                }, null, 8, ["modelValue", "is-invalid"])
              ]),
              _: 1
            }, 8, ["invalid-feedback"])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_form_group_ui, {
              "invalid-feedback": "modals.entrance.fields.firstName.error",
              label: "modals.entrance.fields.firstName.label",
              required: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_input_ui, {
                  modelValue: _ctx.state.firstName,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.state.firstName) = $event)),
                  "is-invalid": _ctx.state.visibleErrors && _ctx.isFirstNameError,
                  placeholder: "modals.entrance.fields.firstName.placeholder"
                }, null, 8, ["modelValue", "is-invalid"])
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_form_group_ui, {
              "invalid-feedback": "modals.entrance.fields.lastName.error",
              label: "modals.entrance.fields.lastName.label",
              required: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_input_ui, {
                  modelValue: _ctx.state.lastName,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.state.lastName) = $event)),
                  "is-invalid": _ctx.state.visibleErrors && _ctx.isLastNameError,
                  placeholder: "modals.entrance.fields.lastName.placeholder"
                }, null, 8, ["modelValue", "is-invalid"])
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_form_group_ui, { label: "modals.entrance.fields.middleName.label" }, {
              default: _withCtx(() => [
                _createVNode(_component_input_ui, {
                  modelValue: _ctx.state.middleName,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.state.middleName) = $event)),
                  placeholder: "modals.entrance.fields.middleName.placeholder"
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_form_group_ui, {
              "invalid-feedback": "modals.entrance.fields.iin.error",
              label: "modals.entrance.fields.iin.label",
              required: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_input_ui, {
                  modelValue: _ctx.state.iin,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.state.iin) = $event)),
                  "is-invalid": _ctx.state.visibleErrors && _ctx.isIinError,
                  mask: { mask: 'Z*', tokens: { 'Z': { pattern: /[0-9]/ }}},
                  maxlength: "12",
                  placeholder: "modals.entrance.fields.iin.placeholder"
                }, null, 8, ["modelValue", "is-invalid"])
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_form_group_ui, {
              "invalid-feedback": "modals.entrance.fields.phone.error",
              label: "modals.entrance.fields.phone.label",
              required: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_input_ui, {
                  modelValue: _ctx.state.phone,
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.state.phone) = $event)),
                  "is-invalid": _ctx.state.visibleErrors && _ctx.isPhoneError,
                  mask: "+7 (###) ###-##-##",
                  placeholder: "modals.entrance.fields.phone.placeholder"
                }, null, 8, ["modelValue", "is-invalid"])
              ]),
              _: 1
            })
          ]),
          (!_ctx.$user.isOVPOSignatory)
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                _createVNode(_component_form_group_ui, {
                  "invalid-feedback": "Выберите учебное заведение",
                  label: "Учебное заведение",
                  required: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_select_ui, {
                      "is-invalid": _ctx.state.visibleErrors && _ctx.isUnivercityIdError,
                      options: _ctx.universities,
                      placeholder: "Учебное заведение",
                      searchable: true,
                      onOnSelected: _ctx.onSelectedUniversity
                    }, null, 8, ["is-invalid", "options", "onOnSelected"])
                  ]),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, [
            _createVNode(_component_button_ui, { "button-class": "btn-primary" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t(_ctx.state.btn)), 1)
              ]),
              _: 1
            })
          ])
        ])
      ], 32)
    ]),
    _: 1
  }, 8, ["id", "title"]))
}