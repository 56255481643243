import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row mb-4" }
const _hoisted_2 = { class: "col-12" }
const _hoisted_3 = { class: "col-12" }
const _hoisted_4 = { class: "col-12" }
const _hoisted_5 = { class: "col-12" }
const _hoisted_6 = { class: "form-check form-check-inline" }
const _hoisted_7 = { class: "form-check-label" }
const _hoisted_8 = { class: "row" }
const _hoisted_9 = { class: "col-12 text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loader_ui = _resolveComponent("loader-ui")!
  const _component_input_ui = _resolveComponent("input-ui")!
  const _component_form_group_ui = _resolveComponent("form-group-ui")!
  const _component_select_ui = _resolveComponent("select-ui")!
  const _component_button_ui = _resolveComponent("button-ui")!
  const _component_modal_ui = _resolveComponent("modal-ui")!

  return (_openBlock(), _createBlock(_component_modal_ui, {
    id: _ctx.id,
    size: "modal-sm",
    title: _ctx.$t(_ctx.state.title)
  }, {
    default: _withCtx(() => [
      _createElementVNode("form", {
        class: "loader",
        onSubmit: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.send && _ctx.send(...args)), ["prevent"]))
      }, [
        _createVNode(_component_loader_ui, {
          "is-show": _ctx.state.loader
        }, null, 8, ["is-show"]),
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_form_group_ui, {
              "invalid-feedback": "modals.turnstile.fields.name.error",
              label: "modals.turnstile.fields.name.label"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_input_ui, {
                  modelValue: _ctx.state.name,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.name) = $event)),
                  "is-invalid": _ctx.state.visibleErrors && _ctx.isNameError,
                  placeholder: "modals.turnstile.fields.name.placeholder"
                }, null, 8, ["modelValue", "is-invalid"])
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_form_group_ui, {
              "invalid-feedback": "modals.turnstile.fields.sn.error",
              label: "modals.turnstile.fields.sn.label"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_input_ui, {
                  modelValue: _ctx.state.sn,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.state.sn) = $event)),
                  "is-invalid": _ctx.state.visibleErrors && _ctx.isSnError,
                  mask: { mask: 'Z*', tokens: { 'Z': { pattern: /[0-9]/ }}},
                  placeholder: "modals.turnstile.fields.sn.placeholder"
                }, null, 8, ["modelValue", "is-invalid"])
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_form_group_ui, {
              "invalid-feedback": "modals.turnstile.fields.entrance.error",
              label: "modals.turnstile.fields.entrance.label"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_select_ui, {
                  "is-invalid": _ctx.state.visibleErrors && _ctx.isEntranceIdError,
                  options: _ctx.entrances,
                  placeholder: "modals.turnstile.fields.entrance.placeholder",
                  searchable: true,
                  values: [_ctx.state.entranceId],
                  onOnSelected: _ctx.onSelectedEntrance
                }, null, 8, ["is-invalid", "options", "values", "onOnSelected"])
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("label", _hoisted_6, [
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.state.reverseDirection) = $event)),
                class: "form-check-input",
                type: "checkbox"
              }, null, 512), [
                [_vModelCheckbox, _ctx.state.reverseDirection]
              ]),
              _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t('modals.turnstile.fields.reverseDirection.label')), 1)
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_button_ui, { "button-class": "btn-primary" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t(_ctx.state.btn)), 1)
              ]),
              _: 1
            })
          ])
        ])
      ], 32)
    ]),
    _: 1
  }, 8, ["id", "title"]))
}