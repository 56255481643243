<template>
    <modal-ui
        :id="id"
        size="modal-sm"
        title="Причина">

        <form class="loader" @submit.prevent="send">
            <div class="row mb-4">
                <div class="col-md-12">
                    <form-group-ui
                        invalid-feedback="Выберите причину"
                        label="Причина"
                        required>
                        <select-ui
                            :is-invalid="visibleErrors && isCommentError"
                            :options="comments"
                            placeholder="Причина"
                            :searchable="true"
                            @on-selected="onSelectedComment" />
                    </form-group-ui>
                </div>
            </div>
            <div class="row">
                <div class="col-12 text-center">
                    <button-ui
                        button-class="btn-primary">
                        Выбрать
                    </button-ui>
                </div>
            </div>
        </form>
    </modal-ui>
</template>

<script lang="ts">
import {computed, defineComponent, ref} from "vue";
import { initModal, useModal } from "@/composables/useModal";

export default defineComponent({
    name: "OVPOReasonModal",
    props: {
        id: {
            type: String,
            default: '',
        },
    },
    setup(props) {
	    const modal = useModal(props.id);
        const comment = ref(0)
        const visibleErrors = ref(false)

        const comments = ref([
            { id: 1, name: 'Не обучался' },
            { id: 2, name: 'Отчисление' },
            { id: 3, name: 'Перевод в другое учебное заведение' },
            { id: 4, name: 'Академическая мобильность' },
            { id: 5, name: 'Выпуск' },
            { id: 6, name: 'Смерть' },
            { id: 7, name: 'Другое' },
        ])

        const isCommentError = computed(() => !comment.value)

        function onSelectedComment(c: any) {
            comment.value = c.id
        }

        function send() {
            if(isCommentError.value) {
                visibleErrors.value = true
            }else{
                let c: any = comments.value.find((i: any) => i.id === comment.value)
                modal.ok(c.name);
            }
        }

	    function ok() {
		    modal.ok({
			    asd: 123,
		    });
	    }

        function close() {
            modal.close();
        }

	    function cancel() {
		    modal.cancel({
			    zxc: 123,
		    });
	    }

        return {
            ok,
            cancel,
            close,
            send,
            comments,
            onSelectedComment,
            isCommentError,
            visibleErrors
        };
    },
});
</script>

<style scoped lang="scss">

</style>
