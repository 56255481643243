<template>
    <modal-ui
        :id="id"
        size="modal-sm"
        :title="$t(state.title)">
        <form class="loader" @submit.prevent="send">
            <loader-ui :is-show="state.loader" />
            <div class="row mb-4">
                <div class="col-12">
                    <form-group-ui
                        invalid-feedback="Укажите наименование"
                        label="Наименование"
                        required>
                        <input-ui
                            v-model="state.name"
                            :is-invalid="state.visibleErrors && isNameError"
                            placeholder="Наименование" />
                    </form-group-ui>
                </div>
                <div class="col-12">
                    <form-group-ui
                        invalid-feedback="Укажите адрес"
                        label="Адрес"
                        required>
                        <input-ui
                            v-model="state.address"
                            :is-invalid="state.visibleErrors && isAddressError"
                            placeholder="Адрес" />
                    </form-group-ui>
                </div>
                <div class="col-12">
                    <form-group-ui
                        invalid-feedback="Выберите город"
                        label="Город"
                        required>
                        <select-ui
                            :is-invalid="state.visibleErrors && isCityIdError"
                            :options="cities"
                            placeholder="Город"
                            :searchable="true"
                            :values="[state.cityId]"
                            @on-selected="onSelectedCity" />
                    </form-group-ui>
                </div>
                <div class="col-12">
                    <form-group-ui
                        invalid-feedback="Выберите тип"
                        label="Тип"
                        required>
                        <select-ui
                            :is-invalid="state.visibleErrors && isTypeIdError"
                            :options="types"
                            placeholder="Тип"
                            :values="[state.typeId]"
                            @on-selected="onSelectedType" />
                    </form-group-ui>
                </div>
                <div class="col-12">
                    <form-group-ui
                        invalid-feedback="Укажите ИИН руководителя"
                        label="ИИН руководителя"
                        required>
                        <input-ui
                            v-model="state.institutionIin"
                            :is-invalid="state.visibleErrors && isInstitutionIinError"
                            :mask="{ mask: 'Z*', tokens: { 'Z': { pattern: /[0-9]/ }}}"
                            maxlength="12"
                            minlength="12"
                            placeholder="ИИН руководителя" />
                    </form-group-ui>
                </div>
                <div class="col-12">
                    <form-group-ui
                        invalid-feedback="Укажите БИН ЮЛ"
                        label="БИН ЮЛ"
                        required>
                        <input-ui
                            v-model="state.institutionBin"
                            :is-invalid="state.visibleErrors && isInstitutionBinError"
                            :mask="{ mask: 'Z*', tokens: { 'Z': { pattern: /[0-9]/ }}}"
                            maxlength="12"
                            minlength="12"
                            placeholder="БИН ЮЛ" />
                    </form-group-ui>
                </div>
                <div class="col-12">
                    <form-group-ui
                        invalid-feedback="Укажите имя руководителя"
                        label="Имя руководителя"
                        required>
                        <input-ui
                            v-model="state.institutionFirstName"
                            :is-invalid="state.visibleErrors && isInstitutionFirstNameError"
                            placeholder="Имя руководителя" />
                    </form-group-ui>
                </div>
                <div class="col-12">
                    <form-group-ui
                        invalid-feedback="Укажите фамилию руководителя"
                        label="Фамилия руководителя"
                        required>
                        <input-ui
                            v-model="state.institutionLastName"
                            :is-invalid="state.visibleErrors && isInstitutionLastNameError"
                            placeholder="Фамилия руководителя" />
                    </form-group-ui>
                </div>
                <div class="col-12">
                    <form-group-ui
                        invalid-feedback="Укажите отчество руководителя"
                        label="Отчество руководителя">
                        <input-ui
                            v-model="state.institutionMiddleName"
                            :is-invalid="state.visibleErrors && isInstitutionMiddleNameError"
                            placeholder="Отчество руководителя" />
                    </form-group-ui>
                </div>
            </div>
            <div class="row">
                <div class="col-12 text-center">
                    <button-ui
                        button-class="btn-primary">
                        {{ $t(state.btn) }}
                    </button-ui>
                </div>
            </div>
        </form>
    </modal-ui>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, computed, ref, watch } from "vue";
import { initModal, useModal } from "@/composables/useModal";
import api from "@/api";
import { ICity } from "@/@types/cities";

export default defineComponent({
    name: "EntranceAEModal",
    props: {
        id: {
            type: String,
            default: '',
        },
    },
    setup(props) {
        const modal = useModal(props.id);

        const state = reactive({
            title: '',
            type: 'add',
            btn: '',
            id: 0,
            name: '',
            address: '',
            cityId: 0,
            typeId: '',
            institutionIin: '',
            institutionBin: '',
            institutionFirstName: '',
            institutionLastName: '',
            institutionMiddleName: '',
            visibleErrors: false,
            loader: false,
        });
        const cities = ref([]);
        const types = [
            { id: 'college', name: 'Колледж' },
            { id: 'university', name: 'Университет' },
        ]

        const checkNullable = (value: any, checkLength = false) => {
            if (value === null || value === undefined){
                return true;
            }
            if (checkLength){
                return value.length !== 12;
            }
        };

        const isNameError = computed(() => !state.name.length);
        const isAddressError = computed(() => !state.address.length);
        const isCityIdError = computed(() => !state.cityId);
        const isTypeIdError = computed(() => !state.typeId);
        const isInstitutionFirstNameError = computed(() => checkNullable(state.institutionFirstName));
        const isInstitutionLastNameError = computed(() => checkNullable(state.institutionLastName));
        // const isInstitutionMiddleNameError = computed(() => checkNullable(state.institutionMiddleName));
        const isInstitutionMiddleNameError = computed(() => false);
        const isInstitutionBinError = computed(() => checkNullable(state.institutionBin, true));
        const isInstitutionIinError = computed(() => checkNullable(state.institutionIin, true));

        const errors = computed(() => isNameError.value || isAddressError.value || isCityIdError.value || isTypeIdError.value || isInstitutionIinError.value || isInstitutionFirstNameError.value || isInstitutionLastNameError.value || isInstitutionMiddleNameError.value || isInstitutionBinError.value);

        onMounted((() => {
            state.title = 'Добавление учебного заведения';
            state.btn = 'Добавить';

            api.v1.info.cities.get().then((r: any) => {
                cities.value = r.data.data;
            });

            console.log(modal.data)

            if (modal.data.hasOwnProperty('institution')) {
                if (modal.data.type === 'edit') {
                    state.title = 'Редактирование учебного заведения';
                    state.btn = 'Сохранить';
                    state.type = 'edit';
                    state.id = modal.data.institution.id;
                    state.name = modal.data.institution.name;
                    state.address = modal.data.institution.address;
                    state.cityId = modal.data.institution.cityId;
                    state.typeId = modal.data.institution.type;
                    state.institutionIin = modal.data.institution.institution_iin;
                    state.institutionBin = modal.data.institution.institution_bin;
                    state.institutionFirstName = modal.data.institution.institution_firstname;
                    state.institutionLastName = modal.data.institution.institution_lastname;
                    state.institutionMiddleName = modal.data.institution.institution_middlename;
                }
            }
        }));

        function onSelectedCity(city: any) {
            state.cityId = city.id;
        }

        function onSelectedType(type: any) {
            state.typeId = type.id;
        }

        function send() {
            if (errors.value) {
                state.visibleErrors = true;
            } else {
                state.visibleErrors = false;
                state.loader = true;
                if (state.type === 'add') {
                    api.v1.institutions.create({
                        name: state.name,
                        address: state.address,
                        cityId: state.cityId,
                        type: state.typeId,
                        institutionIin: state.institutionIin,
                        institutionBin: state.institutionBin,
                        institutionLastName: state.institutionLastName,
                        institutionFirstName: state.institutionFirstName,
                        institutionMiddleName: state.institutionMiddleName,
                    }).then((r: any) => {
                        modal.ok({
                            institution: r.data.data,
                        });
                    }).finally(() => state.loader = false);
                }else{
                    api.v1.institutions.update(state.id, {
                        name: state.name,
                        address: state.address,
                        cityId: state.cityId,
                        type: state.typeId,
                        institutionIin: state.institutionIin,
                        institutionBin: state.institutionBin,
                        institutionLastName: state.institutionLastName,
                        institutionFirstName: state.institutionFirstName,
                        institutionMiddleName: state.institutionMiddleName,
                    }).then((r: any) => {
                        modal.ok({
                            institution: r.data.data,
                        });
                    }).finally(() => state.loader = false);
                }
            }
        }

        return {
            state,
            cities,
            isNameError,
            isAddressError,
            isCityIdError,
            isTypeIdError,
            isInstitutionIinError,
            isInstitutionBinError,
            isInstitutionFirstNameError,
            isInstitutionLastNameError,
            isInstitutionMiddleNameError,
            onSelectedCity,
            onSelectedType,
            send,
            types
        };
    },
});
</script>

<style scoped lang="scss">

</style>
