import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "alert alert-danger m-0" }
const _hoisted_3 = { class: "alert-message text-center" }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "alert alert-danger m-0" }
const _hoisted_6 = { class: "alert-message text-center" }
const _hoisted_7 = { key: 2 }
const _hoisted_8 = { class: "h4 text-center mb-2" }
const _hoisted_9 = { class: "text-center mb-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_modal_ui = _resolveComponent("modal-ui")!

  return (_openBlock(), _createBlock(_component_modal_ui, {
    id: _ctx.id,
    size: "modal-sm",
    title: _ctx.$t('modals.checkCard.title')
  }, {
    default: _withCtx(() => [
      (_ctx.errorCode === 'human_not_exist')
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('modals.checkCard.humanNotExist')), 1)
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.errorCode === 'human_exist')
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('modals.checkCard.humanExist')), 1)
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.human !== null)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.human.firstName) + " " + _toDisplayString(_ctx.human.lastName) + " " + _toDisplayString(_ctx.human.middleName), 1),
            _createElementVNode("div", _hoisted_9, [
              _createTextVNode(" ИИН: "),
              _createElementVNode("strong", null, _toDisplayString(_ctx.human.iin), 1)
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["id", "title"]))
}