<template>
    <modal-ui
        :id="id"
        title="Заголовок модалки">
        <div @click="save">
            Сохранить
        </div>
    </modal-ui>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { initModal, useModal } from "@/composables/useModal";

export default defineComponent({
    name: "TestModal",
    props: {
        id: {
            type: String,
            default: '',
        },
    },
    setup(props) {
	    const modal = useModal(props.id);

	    function ok() {
		    modal.ok({
			    asd: 123,
		    });
	    }

        function close() {
            modal.close();
        }

	    function cancel() {
		    modal.cancel({
			    zxc: 123,
		    });
	    }

        return {
            ok, cancel, close,
        };
    },
});
</script>

<style scoped lang="scss">

</style>
