<template>
    <modal-ui
        :id="id"
        size="modal-sm"
        :title="$t('modals.rejectModeration.title')">
        <form class="loader" @submit.prevent="reject">
            <div class="row mb-4">
                <div class="col-12">
                    <text-area-ui v-model="comment" placeholder="Укажите причину отклонения" />
                </div>
            </div>
            <div class="col-12 text-center">
                <button-ui
                    button-class="btn-danger"
                    :disabled="!comment.length">
                    Отклонить
                </button-ui>
            </div>
        </form>
    </modal-ui>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { initModal, useModal } from "@/composables/useModal";

export default defineComponent({
    name: "CheckCardModal",
    props: {
        id: {
            type: String,
            default: '',
        },
    },
    setup: function (props) {
        const modal = useModal(props.id);

        const comment = ref('');

        onMounted(() => {

        });

        function ok() {
            modal.ok({
                asd: 123,
            });
        }

        function cancel() {
            modal.cancel({
                zxc: 123,
            });
        }

        function reject() {
            modal.ok({
                comment: comment.value,
            });
        }

        return {
            ok, cancel, comment, reject,
        };
    },
});
</script>

<style scoped lang="scss">

</style>
