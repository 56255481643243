<template>
    <modal-ui
        :id="id"
        size="modal-md"
        :title="$t(state.title)">
        <form class="loader" @submit.prevent="send">
            <loader-ui :is-show="state.loader" />
            <div class="row mb-3">
                <div class="col-12">
                    <div class="h4">
                        Данные общежития
                    </div>
                </div>
            </div>
            <div class="row mb-4">
                <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <form-group-ui
                        invalid-feedback="modals.entrance.fields.name.error"
                        label="modals.entrance.fields.name.label"
                        required>
                        <input-ui
                            v-model="state.name"
                            :is-invalid="state.visibleErrors && isNameError"
                            placeholder="modals.entrance.fields.name.placeholder" />
                    </form-group-ui>
                </div>
                <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <form-group-ui
                        invalid-feedback="modals.entrance.fields.city.error"
                        label="modals.entrance.fields.city.label"
                        required>
                        <select-ui
                            :is-invalid="state.visibleErrors && isCityIdError"
                            :options="cities"
                            placeholder="modals.entrance.fields.city.placeholder"
                            :searchable="true"
                            :values="[ state.cityId ]"
                            @on-selected="onSelectedCity" />
                    </form-group-ui>
                </div>
                <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <form-group-ui
                        invalid-feedback="modals.entrance.fields.capacity.error"
                        label="modals.entrance.fields.capacity.label"
                        required>
                        <input-ui
                            v-model="state.capacity"
                            :is-invalid="state.visibleErrors && isCapacityError"
                            :mask="{ mask: 'Z*', tokens: { 'Z': { pattern: /[0-9]/ }}, preprocessor: v => v.replace(/^0+/g, '')}"
                            placeholder="modals.entrance.fields.capacity.placeholder" />
                    </form-group-ui>
                </div>
                <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <form-group-ui
                        invalid-feedback="modals.entrance.fields.address.error"
                        label="modals.entrance.fields.address.label"
                        required>
                        <input-ui
                            v-model="state.address"
                            :is-invalid="state.visibleErrors && isAddressError"
                            placeholder="modals.entrance.fields.address.placeholder" />
                    </form-group-ui>
                </div>
                <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <form-group-ui
                        invalid-feedback="modals.entrance.fields.companyName.error"
                        label="modals.entrance.fields.companyName.label"
                        required>
                        <input-ui
                            v-model="state.companyName"
                            :is-invalid="state.visibleErrors && isCompanyNameError"
                            placeholder="modals.entrance.fields.companyName.placeholder" />
                    </form-group-ui>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-12">
                    <div class="h4">
                        Данные руководителя
                    </div>
                </div>
            </div>
            <div class="row mb-4">
                <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <form-group-ui
                        invalid-feedback="modals.entrance.fields.firstName.error"
                        label="modals.entrance.fields.firstName.label"
                        required>
                        <input-ui
                            v-model="state.entrance_firstname"
                            :is-invalid="state.visibleErrors && isEntranceFirstNameError"
                            placeholder="modals.entrance.fields.firstName.placeholder" />
                    </form-group-ui>
                </div>
                <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <form-group-ui
                        invalid-feedback="modals.entrance.fields.lastName.error"
                        label="modals.entrance.fields.lastName.label"
                        required>
                        <input-ui
                            v-model="state.entrance_lastname"
                            :is-invalid="state.visibleErrors && isEntranceLastNameError"
                            placeholder="modals.entrance.fields.lastName.placeholder" />
                    </form-group-ui>
                </div>
                <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <form-group-ui
                        label="modals.entrance.fields.middleName.label">
                        <input-ui
                            v-model="state.entrance_middlename"
                            placeholder="modals.entrance.fields.middleName.placeholder" />
                    </form-group-ui>
                </div>
                <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <form-group-ui
                        invalid-feedback="modals.entrance.fields.iin.error"
                        label="modals.entrance.fields.iin.label"
                        required>
                        <input-ui
                            v-model="state.entrance_iin"
                            :is-invalid="state.visibleErrors && isEntranceIinError"
                            :mask="{ mask: 'Z*', tokens: { 'Z': { pattern: /[0-9]/ }}}"
                            maxlength="12"
                            placeholder="modals.entrance.fields.iin.placeholder" />
                    </form-group-ui>
                </div>
                <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <form-group-ui
                        invalid-feedback="modals.entrance.fields.bin_yl.error"
                        label="modals.entrance.fields.bin_yl.label"
                        required>
                        <input-ui
                            v-model="state.entrance_bin"
                            :is-invalid="state.visibleErrors && isEntranceBinError"
                            :mask="{ mask: 'Z*', tokens: { 'Z': { pattern: /[0-9]/ }}}"
                            maxlength="12"
                            placeholder="modals.entrance.fields.bin.placeholder" />
                    </form-group-ui>
                </div>
            </div>
            <div class="row mb-3" v-if="state.type === 'add'">
                <div class="col-12">
                    <div class="h4">
                        Данные пользователя
                    </div>
                </div>
            </div>
            <div class="row mb-4" v-if="state.type === 'add'">
                <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <form-group-ui
                        :invalid-feedback="!state.isLoginExist ? 'modals.entrance.fields.login.error' : 'Такой логин уже используется'"
                        label="modals.entrance.fields.login.label"
                        required>
                        <input-ui
                            v-model="state.login"
                            :is-invalid="state.visibleErrors && isLoginError"
                            placeholder="modals.entrance.fields.login.placeholder" />
                    </form-group-ui>
                </div>
                <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <form-group-ui
                        :invalid-feedback="passwordInvalidFeedback"
                        label="modals.entrance.fields.password.label"
                        required>
                        <input-ui
                            v-model="state.password"
                            :is-invalid="state.visibleErrors && (isPasswordError ? isPasswordError : passwordChecking)"
                            placeholder="modals.entrance.fields.password.placeholder"
                            type="password" />
                    </form-group-ui>
                </div>
                <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <form-group-ui
                        :invalid-feedback="confirmInvalidFeedback"
                        label="modals.entrance.fields.confirm.label"
                        required>
                        <input-ui
                            v-model="state.confirm"
                            :is-invalid="state.visibleErrors && (isConfirmError ? isConfirmError : passwordChecking)"
                            placeholder="modals.entrance.fields.confirm.placeholder"
                            type="password" />
                    </form-group-ui>
                </div>
                <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <form-group-ui
                        invalid-feedback="modals.entrance.fields.firstName.error"
                        label="modals.entrance.fields.firstName.label"
                        required>
                        <input-ui
                            v-model="state.firstName"
                            :is-invalid="state.visibleErrors && isFirstNameError"
                            placeholder="modals.entrance.fields.firstName.placeholder" />
                    </form-group-ui>
                </div>
                <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <form-group-ui
                        invalid-feedback="modals.entrance.fields.lastName.error"
                        label="modals.entrance.fields.lastName.label"
                        required>
                        <input-ui
                            v-model="state.lastName"
                            :is-invalid="state.visibleErrors && isLastNameError"
                            placeholder="modals.entrance.fields.lastName.placeholder" />
                    </form-group-ui>
                </div>
                <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <form-group-ui
                        label="modals.entrance.fields.middleName.label">
                        <input-ui
                            v-model="state.middleName"
                            placeholder="modals.entrance.fields.middleName.placeholder" />
                    </form-group-ui>
                </div>
                <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <form-group-ui
                        invalid-feedback="modals.entrance.fields.iin.error"
                        label="modals.entrance.fields.iin.label"
                        required>
                        <input-ui
                            v-model="state.iin"
                            :is-invalid="state.visibleErrors && isIinError"
                            :mask="{ mask: 'Z*', tokens: { 'Z': { pattern: /[0-9]/ }}}"
                            maxlength="12"
                            placeholder="modals.entrance.fields.iin.placeholder" />
                    </form-group-ui>
                </div>
                <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <form-group-ui
                        invalid-feedback="modals.entrance.fields.phone.error"
                        label="modals.entrance.fields.phone.label"
                        required>
                        <input-ui
                            v-model="state.phone"
                            :is-invalid="state.visibleErrors && isPhoneError"
                            mask="+7 (###) ###-##-##"
                            placeholder="modals.entrance.fields.phone.placeholder" />
                    </form-group-ui>
                </div>
            </div>
            <div class="row mb-3" v-if="state.type === 'edit'">
                <div class="col-12">
                    <div>
                        Контакт: {{ state.contact }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 text-center">
                    <button-ui
                        button-class="btn-primary">
                        {{ $t(state.btn) }}
                    </button-ui>
                </div>
            </div>
        </form>
    </modal-ui>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, computed, ref, watch } from "vue";
import { initModal, useModal } from "@/composables/useModal";
import api from "@/api";
import { ICity } from "@/@types/cities";

export default defineComponent({
    name: "EntranceAEModal",
    props: {
        id: {
            type: String,
            default: '',
        },
    },
    setup(props) {
	    const modal = useModal(props.id);

        const state = reactive({
            title: '',
            btn: '',
            id: 0,
            type: 'add',
            name: '',
            cityId: 0,
            capacity: 0,
            address: '',
            login: '',
            password: '',
            confirm: '',
            contact: '',
            companyName: '',
            firstName: '',
            lastName: '',
            middleName: '',
            iin: '',
            phone: '',
            visibleErrors: false,
            loader: false,
            isLoginExist: false,
            entrance_bin: '',
            entrance_iin: '',
            entrance_firstname: '',
            entrance_middlename: '',
            entrance_lastname: '',
        });
        const cities = ref([]);

        const checkNullable = (value: any, checkLength = false) => {
            if (value === null || value === undefined){
                return true;
            }
            if (checkLength){
                return value.length !== 12;
            }
        };

        const isNameError = computed(() => !state.name.length);
        const isCityIdError = computed(() => !state.cityId);
        const isCapacityError = computed(() => !state.capacity);
        const isAddressError = computed(() => !state.address.length);
        const isEntranceIinError = computed(() => checkNullable(state.entrance_iin, true));
        const isEntranceFirstNameError = computed(() => checkNullable(state.entrance_firstname));
        const isEntranceLastNameError = computed(() => checkNullable(state.entrance_lastname));
        // const isEntranceMiddleNameError = computed(() => checkNullable(state.entrance_middlename));
        const isEntranceMiddleNameError = computed(() => false);
        const isEntranceBinError = computed(() => checkNullable(state.entrance_bin, true));
        const isCompanyNameError = computed(() => !state.companyName.length);
        const isLoginError = computed(() => !state.login.length || state.isLoginExist);
        const isPasswordError = computed(() => !state.password.length);
        const isConfirmError = computed(() => !state.confirm.length);
        const isFirstNameError = computed(() => !state.firstName.length);
        const isLastNameError = computed(() => !state.lastName.length);
        const isIinError = computed(() => state.iin.length !== 12);
        const isPhoneError = computed(() => state.phone.length !== 18);
        const passwordChecking = computed(() => state.password.length && state.confirm.length && state.password !== state.confirm);
        const passwordInvalidFeedback = computed(() => isPasswordError.value ? 'modals.entrance.fields.password.error' : 'modals.entrance.fields.password.error2');
        const confirmInvalidFeedback = computed(() => isConfirmError.value ? 'modals.entrance.fields.confirm.error' : 'modals.entrance.fields.confirm.error2');

        const errors = computed(() => {
            if (state.type === 'add') {
                return isNameError.value ||
                    isCityIdError.value ||
                    isCapacityError.value ||
                    isAddressError.value ||
                    isEntranceFirstNameError.value ||
                    isEntranceLastNameError.value ||
                    isEntranceMiddleNameError.value ||
                    isEntranceIinError.value ||
                    isEntranceBinError.value ||
                    isLoginError.value ||
                    isCompanyNameError.value ||
                    isFirstNameError.value ||
                    isLastNameError.value ||
                    isIinError.value ||
                    isPhoneError.value ||
                    isPasswordError.value ||
                    isConfirmError.value ||
                    passwordChecking.value
            }else{
                return isNameError.value ||
                    isCityIdError.value ||
                    isCapacityError.value ||
                    isAddressError.value ||
                    isEntranceFirstNameError.value ||
                    isEntranceMiddleNameError.value ||
                    isEntranceLastNameError.value ||
                    isEntranceIinError.value ||
                    isEntranceBinError.value ||
                    isCompanyNameError.value
            }
        });

        onMounted((() => {
            state.title = 'modals.entrance.titleAdd';
            state.btn = 'modals.entrance.btnAdd';

            api.v1.info.cities.get().then((r: any) => {
                cities.value = r.data.data;
            });

            if (modal.data.hasOwnProperty('entrance')) {
                if (modal.data.type === 'edit') {
                    state.title = 'Редактирование общежития';
                    state.btn = 'Сохранить';
                    state.type = 'edit';
                    state.id = modal.data.entrance.id;
                    state.name = modal.data.entrance.name;
                    state.name = modal.data.entrance.name;
                    state.cityId = modal.data.entrance.cityId;
                    state.capacity = modal.data.entrance.capacity;
                    state.address = modal.data.entrance.address;
                    state.entrance_firstname = modal.data.entrance.entrance_firstname;
                    state.entrance_middlename = modal.data.entrance.entrance_middlename;
                    state.entrance_lastname = modal.data.entrance.entrance_lastname;
                    state.entrance_iin = modal.data.entrance.entrance_iin;
                    state.entrance_bin = modal.data.entrance.entrance_bin;
                    state.contact = modal.data.entrance.contact;
                    state.companyName = modal.data.entrance.companyName ? modal.data.entrance.companyName : '';
                }
            }
        }));

        function onSelectedCity(city: ICity) {
            state.cityId = city.id;
        }

        function send() {
            if (errors.value) {
                state.visibleErrors = true;
            } else {
                state.visibleErrors = false;
                state.loader = true;
                if(state.type === 'add') {
                    api.v1.entrances.create({
                        name: state.name,
                        cityId: state.cityId,
                        capacity: state.capacity,
                        address: state.address,
                        entrance_firstname: state.entrance_firstname,
                        entrance_lastname: state.entrance_lastname,
                        entrance_middlename: state.entrance_middlename,
                        entrance_iin: state.entrance_iin,
                        entrance_bin: state.entrance_bin,
                        login: state.login,
                        password: state.password,
                        firstName: state.firstName,
                        companyName: state.companyName,
                        lastName: state.lastName,
                        middleName: state.middleName || null,
                        iin: state.iin,
                        phone: state.phone.replace(/[^0-9]/ig, ''),
                    }).then((r: any) => {
                        modal.ok({
                            entrance: r.data.data,
                        });
                    }).finally(() => state.loader = false);
                }else{
                    api.v1.entrances.update(state.id, {
                        name: state.name,
                        cityId: state.cityId,
                        capacity: String(state.capacity),
                        address: state.address,
                        entrance_bin: state.entrance_bin,
                        entrance_firstname: state.entrance_firstname,
                        entrance_lastname: state.entrance_lastname,
                        entrance_middlename: state.entrance_middlename,
                        entrance_iin: state.entrance_iin,
                        companyName: state.companyName,
                    }).then((r: any) => {
                        modal.ok({
                            entrance: r.data.data,
                        });
                    }).finally(() => state.loader = false);
                }
            }
        }

        watch(() => state.login, (v) => {
            api.v1.entrances.checkLogin({
                login: state.login
            }).then((r: any) => {
                state.isLoginExist = r.data.data
            })
        })

	    function ok() {
		    modal.ok({
			    asd: 123,
		    });
	    }

        function close() {
            modal.close();
        }

	    function cancel() {
		    modal.cancel({
			    zxc: 123,
		    });
	    }

        return {
            ok,
            cancel,
            close,
            state,
            cities,
            isNameError,
            isCityIdError,
            isCapacityError,
            isAddressError,
            isEntranceBinError,
            isEntranceIinError,
            isEntranceFirstNameError,
            isEntranceLastNameError,
            isEntranceMiddleNameError,
            isLoginError,
            isFirstNameError,
            isLastNameError,
            isIinError,
            isPhoneError,
            isCompanyNameError,
            isPasswordError,
            isConfirmError,
            passwordChecking,
            passwordInvalidFeedback,
            confirmInvalidFeedback,
            onSelectedCity,
            send,
        };
    },
});
</script>

<style scoped lang="scss">

</style>
