import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "d-flex justify-content-center align-items-center flex-column" }
const _hoisted_2 = { class: "h4 text-center mb-4" }
const _hoisted_3 = { class: "d-flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_button_ui = _resolveComponent("button-ui")!
  const _component_modal_ui = _resolveComponent("modal-ui")!

  return (_openBlock(), _createBlock(_component_modal_ui, {
    id: _ctx.id,
    size: "modal-sm",
    title: _ctx.$t('confirm')
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.question), 1),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_button_ui, {
            class: "btn-success",
            onClick: _ctx.ok
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.okBtn), 1)
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_button_ui, {
            class: "btn-outline-danger ms-4",
            onClick: _ctx.cancel
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.cancelBtn), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["id", "title"]))
}