import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_modal_ui = _resolveComponent("modal-ui")!

  return (_openBlock(), _createBlock(_component_modal_ui, {
    id: _ctx.id,
    title: "Заголовок модалки"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.save && _ctx.save(...args)))
      }, " Сохранить ")
    ]),
    _: 1
  }, 8, ["id"]))
}