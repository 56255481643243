<template>
    <modal-ui
        :id="id"
        size="modal-sm"
        :title="$t('confirm')">
        <div class="d-flex justify-content-center align-items-center flex-column">
            <div class="h4 text-center mb-4">
                {{ question }}
            </div>
            <div class="d-flex">
                <button-ui class="btn-success" @click="ok">
                    {{ okBtn }}
                </button-ui>
                <button-ui class="btn-outline-danger ms-4" @click="cancel">
                    {{ cancelBtn }}
                </button-ui>
            </div>
        </div>
    </modal-ui>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { initModal, useModal } from "@/composables/useModal";

export default defineComponent({
    name: "ConfirmModal",
    props: {
        id: {
            type: String,
            default: '',
        },
    },
    setup: function (props) {
        const modal = useModal(props.id);
        const question = ref('');
        const okBtn = ref('Да');
        const cancelBtn = ref('Нет');

        onMounted(() => {
            question.value = modal.data.question;
            if (modal.data.hasOwnProperty('ok')) {
                okBtn.value = modal.data.ok;
            }
            if (modal.data.hasOwnProperty('cancel')) {
                cancelBtn.value = modal.data.cancel;
            }
        });

        function ok() {
            modal.ok({
                asd: 123,
            });
        }

        function cancel() {
            modal.cancel({
                zxc: 123,
            });
        }

        return {
            ok, cancel, question, okBtn, cancelBtn,
        };
    },
});
</script>

<style scoped lang="scss">

</style>
